import type { ClaimAction } from "../types/Claim";
import React from "react";
import { getClaimDateClass, getLatestComment, getSoeClaimStatusClass } from "../features/soeUtils";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";

type ClaimActionCardProps = {
    claimAction: ClaimAction,
    isSubmitter: boolean,
    companyDisplayName?: string,
    deleteAction?: (id: number) => void,
};

export default class ClaimActionCard extends React.Component<ClaimActionCardProps> {
    render() {
        const { claimAction, isSubmitter, deleteAction, companyDisplayName } = this.props;

        const isEditable = claimAction.Status === 3 || claimAction.Status === 0;
        const claimStatusClass = getSoeClaimStatusClass(claimAction.Status);
        const claimDateClass = getClaimDateClass(claimAction.Date);
        const claimLink = isSubmitter
            ? "/Home/UpdateClaim"
            : "/Home/ApproveClaim";

        return (
            <div className="soe-person-card">
                <div className="soe-person-card-status-row">
                    <div className="flex flex-row">
                        <span className="soe-person-card-code">{claimAction.EmployeeCode}</span>
                        {claimAction.DepartmentCode && (
                            <span>{claimAction.DepartmentCode}</span>
                        )}
                    </div>
                    <span className={`no-underline ${claimStatusClass}`}>{claimAction.StatusStr}</span>
                </div>
                <span style={{ marginBottom: "4px" }}>{claimAction.EmployeeName}</span>
                <div className="soe-person-card-category">
                    <p className="soe-person-card-category-text">{claimAction.Category}</p>
                </div>
                <div className="flex soe-person-card-company">
                    <span data-field-name="CompanyName" className="soe-person-card-company-text">
                        {companyDisplayName}
                    </span>
                </div>
                <div className="soe-person-card-date-of-receipt">
                    <span className="soe-person-card-data-title">Date of receipt</span>
                    <span className={`soe-person-card-data-value ${claimDateClass}`}>{claimAction.DateStr}</span>
                </div>
                <div className="soe-person-card-dates-and-amount">
                    <div className="flex flex-row" style={{ gap: "8px" }}>
                        <div className="flex flex-col">
                            <span className="soe-person-card-data-title">Submitted on</span>
                            <span className="soe-person-card-data-value">{claimAction.DateSubmittedStr}</span>
                        </div>
                        {claimAction.DateModifiedStr && (
                            <div className="flex flex-col">
                                <span className="soe-person-card-data-title">Updated on</span>
                                <span className="soe-person-card-data-value">{claimAction.DateModifiedStr}</span>
                            </div>
                        )}
                    </div>
                    <div className="soe-person-card-amount">
                        {claimAction.Amount}&nbsp;
                        {claimAction.Currency}
                    </div>
                </div>
                {(claimAction.ApproverId || claimAction.Comments) && (
                    <div className="soe-person-card-divider"/>
                )}
                <div className="soe-person-card-approver">
                    {claimAction.ApproverId && (
                        <div className="flex flex-col">
                            <span className="soe-person-card-data-title">Approver</span>
                            <p style={{ margin: 0 }}>{claimAction.ApproverName}</p>
                        </div>
                    )}
                    {claimAction.Comments && (
                        <div className="flex flex-col">
                            <span className="soe-person-card-data-title">Last comment</span>
                            <p style={{ margin: 0 }}>{getLatestComment(claimAction.Comments)}</p>
                        </div>
                    )}
                </div>
                <div className="soe-person-card-actions">
                    <a
                        href={`${claimLink}?${new URLSearchParams({ claimId: claimAction.ClaimId.toString() })}`}
                        className="soe-person-card-actions-button info"
                    >
                        { isEditable && isSubmitter ? (
                            <>
                                <EditIcon width={20} height={20} />
                                Edit
                            </>
                        ) : "View" }
                    </a>
                    {deleteAction && isEditable && (
                        <a
                            onClick={() => deleteAction(claimAction.ClaimId)}
                            className="soe-person-card-actions-button danger"
                        >
                            <DeleteIcon width={20} height={20} />
                            Delete
                        </a>
                    )}
                </div>
            </div>
        );
    }
}
