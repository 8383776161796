import type { OfficerFlightDeckFeesPersonAaiFd } from "../types/Api/Contract";
import type { CompanyName, COMPANYNAME, CompanyWise,CREWCODE, Department } from "./Api";
import api from "./Api";
import type { IataToIana, JsonValue } from "../types/utility";
import { entries, fromEntries,toCOMPANYNAMEUC,toCrewCodeUc } from "../utils/typing";
import type { PortalCompanyBase } from "../types/Api/Company";
import type { StoredFinalization, StoredFinalizationData, StoredFinalizationsMapping } from "../types/Api/Invoice";
import type { NavCompanyData } from "../types/NavisionPayslip";
import AppEnvironment from "./AppEnvironment";

type FuncCache = Record<string, Promise<JsonValue>>;

const MEMOIZE_CACHE: Record<symbol, FuncCache> = {};

export function deepFreeze<T extends JsonValue>(object: T): T {
    if (!object || typeof object !== "object" && typeof object !== "function") {
        return object;
    }

    const occurrences = new WeakSet<{}>();
    function deepFreezeCircularlySafe<T extends {}>(object: T) {
        if (occurrences.has(object)) {
            return object;
        }
        occurrences.add(object);
        const propNames = Reflect.ownKeys(object);
        for (const name of propNames) {
            const value = object[name as keyof typeof object];
            if ((value && typeof value === "object") || typeof value === "function") {
                deepFreezeCircularlySafe(value);
            }
        }

        return Object.freeze(object);
    }
    return deepFreezeCircularlySafe(object);
}

/**
 * be careful not to pass excess properties in params, as typescript can not check that input
 * exactly matches the specified type - it only checks that input is a subtype of that type
 */
function memoize<
    TParams extends JsonValue[],
    TResult extends JsonValue
>(func: (...params: TParams) => Promise<TResult>) {
    const funcKey = Symbol("memoized function " + String(func));
    const funcCache: FuncCache = MEMOIZE_CACHE[funcKey] = {};
    return async (...params: TParams): Promise<TResult> => {
        const paramsKey = JSON.stringify(params);
        if (paramsKey in funcCache) {
            const cachedPromise = funcCache[paramsKey] as Promise<TResult>;
            // only reuse successful responses
            try {
                return await cachedPromise;
            } catch {}
        }
        const whenResult = func(...params).then(deepFreeze);
        funcCache[paramsKey] = whenResult;
        return whenResult;
    };
}

export const getAaiOfficerFlightDeckFeesMapping = memoize(
    (year: number, month: number): Promise<Record<CREWCODE, OfficerFlightDeckFeesPersonAaiFd>> =>
        api.Contract.GetAaiOfficerFlightDeckFeesTable({ year, month }).then(rows => {
            return fromEntries(rows.map(row => [
                toCrewCodeUc(row.employeeCode), row
            ]));
        })
);

let whenIataToIana: null | Promise<IataToIana> = null;

export async function getIataToIana(): Promise<IataToIana> {
    if (whenIataToIana) {
        try {
            return await whenIataToIana;
        } catch {}
    }
    const endpoint = AppEnvironment.API_BASE_URL + "/Content/data/iata_to_timezone.json";
    whenIataToIana = fetch(endpoint)
        .then(rs => rs.json())
        .then((mapping: IataToIana) => Object.freeze(mapping));
    return whenIataToIana;
}

export type AllCompany = NavCompanyData & {
    COMPANYNAME: COMPANYNAME,
    navEntry?: CompanyWise,
    portalEntry?: PortalCompanyBase,
    departments: Department[],
};

export type AllCompanies = Record<COMPANYNAME, AllCompany>;

async function fetchAllCompanies(): Promise<AllCompanies> {
    const whenNavCompanyToData = api.Company.GetNavCompanyToData();
    const whenDisplayNameRecords = api.Company.GetAllCompaniesWise();
    const whenPortalCompanies = api.Company.GetCompanies();
    const whenDepartments = api.Company.GetAllCompaniesDepartments();
    const navCompanyToData = await whenNavCompanyToData;
    const displayNameRecords = await whenDisplayNameRecords;
    const portalCompanies = await whenPortalCompanies;
    const departments = await whenDepartments;
    const allCompaniesList = entries(navCompanyToData).map(([companyName, data]) => {
        const COMPANYNAME = toCOMPANYNAMEUC(companyName);
        return {
            COMPANYNAME: COMPANYNAME,
            navEntry: displayNameRecords.find(dnr => dnr.Code.toUpperCase() === COMPANYNAME),
            portalEntry: portalCompanies.find(pc => pc.Name.toUpperCase() === COMPANYNAME),
            departments: departments.filter(d => d.CompanyName.toUpperCase() === COMPANYNAME),
            company: data.company,
            glSetup: data.glSetup,
            companySpecificFields: data.companySpecificFields,
        };
    }).sort((a,b) => {
        const aName = a.navEntry?.DisplayName ?? a.COMPANYNAME;
        const bName = b.navEntry?.DisplayName ?? b.COMPANYNAME;
        return aName === bName ? 0 : aName < bName ? -1 : 1;
    }).sort((a,b) => {
        if (a.portalEntry && !b.portalEntry) {
            return -1;
        } else if (!a.portalEntry && b.portalEntry) {
            return 1;
        } else {
            return 0;
        }
    });
    return fromEntries(allCompaniesList.map(ac => [ac.COMPANYNAME, ac]));
}

let whenAllCompanies: null | Promise<AllCompanies> = null;

export async function getAllCompanies(): Promise<AllCompanies> {
    if (whenAllCompanies) {
        try {
            return await whenAllCompanies;
        } catch {}
    }
    whenAllCompanies = fetchAllCompanies().then(deepFreeze);
    return whenAllCompanies;
}


function computePayrollFinalizationsMapping(finalizations: StoredFinalization[]): StoredFinalizationsMapping<StoredFinalizationData> {
    const result: StoredFinalizationsMapping<StoredFinalizationData> = {};
    for (const { company_name, year, month, department, ...restData } of finalizations) {
        result[company_name]              = result[company_name] ?? {};
        result[company_name][year]        = result[company_name][year] ?? {};
        result[company_name][year][month] = result[company_name][year][month] ?? {
            perDepartment: {},
            anyDepartment: null,
        };
        if (department) {
            result[company_name][year][month].perDepartment[department] = restData;
        } else {
            result[company_name][year][month].anyDepartment = restData;
        }
    }
    return deepFreeze(result);
}


let whenPayrollFinalizationsMapping: null | Promise<StoredFinalizationsMapping<StoredFinalizationData>> = null;

export async function getPayrollFinalizationsMapping(): Promise<StoredFinalizationsMapping<StoredFinalizationData>> {
    if (whenPayrollFinalizationsMapping) {
        try {
            return await whenPayrollFinalizationsMapping;
        } catch {}
    }
    whenPayrollFinalizationsMapping = api.Invoice
        .GetNavPayrollFinalizationsList()
        .then(finalizations => computePayrollFinalizationsMapping(finalizations))
        .then(deepFreeze);
    return whenPayrollFinalizationsMapping;
}

export const getAllNavBases = memoize(api.Company.GetBaseWise);
export const getAllNavActivities = memoize(api.Activity.GetActivitiesWise);
export const getNavCompanyProjects = memoize(
    (company: CompanyName) => api.Company.GetProjectWise({ company })
);
export const getNavCompanyFeeEntryTypes = memoize(
    (company: CompanyName) => api.Contract.GetFeeEntryTypes({ company })
);
export const getNavCompanyWageContractFixedLines = memoize(
    (company: CompanyName) => api.Contract.GetWageContractFixedLines({ company })
);
export const getNavCompanyWageContractFeeEntryScales = memoize(
    (company: CompanyName) => api.Contract.GetWageContractFeeEntryScales({ company })
);
export const getNavCompanyWageContracts = memoize(
    (company: CompanyName) => api.Contract.GetWageContracts({ company })
);
