import * as React from "react";
import type { CompanyName,CREWCODE,NavInvoiceLocator } from "../features/Api";
import api from "../features/Api";


import type { AbsoluteMonth } from "../utils/dates";
import  { decrementMonth } from "../utils/dates";
import  { getMonthStr } from "../utils/dates";
import  { getMonthEndDate,getMonthStartDate } from "../utils/dates";
import { getPastMonth } from "../utils/dates";
import { strcmp } from "../utils/dates";
import type   { CodedSalaryJournalRecord,
    ImpersonalSalaryJournalRecord,NavContractorForBulkCalculation,
    PersonMatch
} from "../features/ExternalRostersApi";
import { FeeEntryType
} from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import NavJournalRecordsTable, { getOfficerNotesText } from "../components/NavJournalRecordsTable";
import type { PayEntry } from "../types/NavisionPayslip";
import { getAaiOfficerFlightDeckFeesMapping } from "../features/StaticData";
import type { PersonMonthRosterLocator } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import {
    fixManually,
    isCabinCrewDepartment,
    isFlightDeckDepartment,
    isMaintenanceDepartment,
    isLoadMasterDepartment,
    isAirAtlanta,
} from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { calculateFeesForFlightDeckPerson,getPrecedingUnterminatedRotationDays } from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import { calculateFeesForCabinCrewPerson } from "../features/roster/air_atlanta/FeeCalculatorCabinCrew";
import type { OfficerFlightDeckFeesPersonAaiFd } from "../types/Api/Contract";
import { brand,neverNull,toCOMPANYNAMEUC, toCrewCodeUc } from "../utils/typing";
import type { RaidoRotationDay } from "../features/roster/air_atlanta/RotationDaysGrouper";
import { getRotationDaysFromFullUnfiltered,raidoToRm } from "../features/roster/air_atlanta/RotationDaysGrouper";
import type  { RosterCrew } from "@mhc/utils/types/integrations/raido/api";
import type { CrewCode } from "@mhc/utils/types/nav";
import { calculateFeesForLoadMasterPerson } from "../features/roster/air_atlanta/FeeCalculatorLoadmaster";
import { HttpResponseError } from "../features/httpUtils";
import { isLeonGraphqlCompany } from "../components/InvoiceRosterView";
import type { MonthStr } from "../types/utility";

type MinPersonMatch = PersonMatch<NavContractorForBulkCalculation>;

type CalculatedPerson = {
    person: MinPersonMatch | PersonMatch,
    journalRecords: CodedSalaryJournalRecord[],
};

type Props = {};
type State = {
    calculatedPeople: CalculatedPerson[],
    year: number,
    month: number,
    loading: boolean,
    includeFlightDeck: boolean,
    includeCabinCrew: boolean,
    includeLdm: boolean,
    includeMtx: boolean,
    includeNoDepartment: boolean,
    COMPANYNAME: CompanyName,
    codeToNavInvoiceItems: Record<string, PayEntry[]> | null,
    officerFlightDeckFeesMapping: Record<string, OfficerFlightDeckFeesPersonAaiFd> | null,
    totalPeople: number | null,
    peopleInitiated: number,
};

function isAaiCabinCrew(person: MinPersonMatch) {
    return isCabinCrewDepartment(person.navEntry.GlobalDimension1Code);
}

function isAaiFlightDeck(person: MinPersonMatch) {
    return isFlightDeckDepartment(person.navEntry.GlobalDimension1Code);
}

function isAaiMtx(person: MinPersonMatch) {
    return isMaintenanceDepartment(person.navEntry.GlobalDimension1Code);
}

function isAaiLdm(person: MinPersonMatch) {
    return isLoadMasterDepartment(person.navEntry.GlobalDimension1Code);
}

function hasNoDepartment(person: MinPersonMatch) {
    return person.navEntry.GlobalDimension1Code === "";
}

function getRotationDays(
    locator: NavInvoiceLocator,
    nocRosterMonths: MonthStrToCrewMap
): RaidoRotationDay[] | null {
    const nocCrew = nocRosterMonths
        .get(getMonthStr(locator))
        ?.get(toCrewCodeUc(locator.employeeCode));

    if (nocCrew) {
        const rmifiedNoc = raidoToRm(nocCrew, locator);
        return getRotationDaysFromFullUnfiltered(rmifiedNoc, null);
    } else {
        return null;
    }
}

type CrewMap = Map<CREWCODE, RosterCrew>;
export type MonthStrToCrewMap = Map<MonthStr, CrewMap>;

async function fetchNocRosterMonths(yearMonth: AbsoluteMonth): Promise<MonthStrToCrewMap> {
    const monthStrToCrews: MonthStrToCrewMap = new Map();
    for (const cursor of [yearMonth, decrementMonth(yearMonth)]) {
        const people = await externalRostersApi.getAirAtlantaRaidoRosters(cursor);
        const crewMap: CrewMap = new Map(people.map(p => [toCrewCodeUc(p.Number), p]));
        monthStrToCrews.set(getMonthStr(cursor), crewMap);
    }
    return monthStrToCrews;
}

export default class InvoiceRosterCalculation extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        require("./InvoiceRosterCalculation.css");
        const search = new URLSearchParams(window.location.search);
        const COMPANYNAME = search.get("COMPANYNAME");
        if (!COMPANYNAME) {
            throw new Error("COMPANYNAME not supplied in URL");
        }
        let { year, month } = getPastMonth(new Date());
        const yearStr = search.get("year");
        const monthStr = search.get("month");
        year = yearStr ? +yearStr : year;
        month = monthStr ? +monthStr : month;
        this.state = {
            calculatedPeople: [],
            year: year,
            month: month,
            loading: true,
            includeFlightDeck: true,
            includeCabinCrew: true,
            includeLdm: true,
            includeMtx: true,
            includeNoDepartment: true,
            COMPANYNAME: brand(COMPANYNAME),
            codeToNavInvoiceItems: null,
            officerFlightDeckFeesMapping: null,
            totalPeople: null,
            peopleInitiated: 0,
        };
        this.fetchData();
    }

    private get invoicedInAirborne() {
        const yearMonthStr = this.yearMonth.year + "-" + String(this.yearMonth.month).padStart(2, "0");
        return yearMonthStr <= "2024-02"
            && this.state.COMPANYNAME === "HEL - AAIUSD";
    }

    private fetchData() {
        const whenClientsMapping = api.Company.get_clients_mapping();
        const whenActiveMhcPeople = whenClientsMapping.then(clientsMapping => {
            const clientMappingEntry = clientsMapping.find(cme => {
                return cme.navision_code?.toUpperCase() === this.state.COMPANYNAME.toUpperCase();
            }) ?? neverNull("Missing RDB mapping for " + this.state.COMPANYNAME);
            return externalRostersApi.getActiveMhcPeople({
                COMPANYNAME: this.state.COMPANYNAME,
                clientId: clientMappingEntry.rdb_id,
            });
        });
        whenActiveMhcPeople.then(people => this.setState({
            totalPeople: people.length,
        }));
        this.fetchCalculatedJournalRecords(whenActiveMhcPeople)
            .finally(() => this.setState({ loading: false }));
        api.Invoice.all_invoice_items_in_payroll({
            company: this.state.COMPANYNAME,
            ...this.yearMonth,
            department: 0,
        }).then(async items => {
            if (this.invoicedInAirborne) {
                // all CCM were moved to Helo database,
                items.push(...await api.Invoice.all_invoice_items_in_payroll({
                    company: brand("Airborne - Malta"),
                    ...this.yearMonth,
                    department: 0,
                }));
            }
            if (items.length === 0) {
                return; // nothing to validate against: start of the month
            }
            const codeToNavInvoiceItems: Record<string, PayEntry[]> = {};
            for (const item of items) {
                codeToNavInvoiceItems[item.Nr_.toUpperCase()] = codeToNavInvoiceItems[item.Nr_.toUpperCase()] ?? [];
                codeToNavInvoiceItems[item.Nr_.toUpperCase()].push(item);
            }
            this.setState({ codeToNavInvoiceItems });
        });
        getAaiOfficerFlightDeckFeesMapping(this.state.year, this.state.month)
            .then(officerFlightDeckFeesMapping => this.setState({ officerFlightDeckFeesMapping }));
    }

    private async calculateFeesForLeonGraphqlPerson(crewCode: CrewCode) {
        const personMonthRoster = await api.Roster.getLeonGraphqlRoster({
            company: this.state.COMPANYNAME,
            month: this.state.month,
            year: this.state.year,
            employeeCode: crewCode,
        }).catch(error => {
            if (error instanceof HttpResponseError && error.response.status === 404) {
                return null;
            } else {
                throw error;
            }
        });
        const asFixManually: ImpersonalSalaryJournalRecord = {
            description: "Fix Manually",
            units: "1",
            fee_entry_type: FeeEntryType(9000),
            service_start_time: getMonthStartDate(this.state),
            service_end_time: getMonthEndDate(this.state),
            payment_per_unit: "0.00",
        };
        const codelessJournalRecords: ImpersonalSalaryJournalRecord[] = !personMonthRoster ? [
            { ...asFixManually, description: "Roster for person was not found" }
        ] : personMonthRoster.journalRecords ?? [
            { ...asFixManually, description: "Fee Calculation Not Implemented" }
        ];
        return codelessJournalRecords
            .map(jr => ({ ...jr, crew_code: crewCode }));
    }

    private async fetchCalculatedJournalRecords(whenActiveMhcPeople: Promise<PersonMatch[]>) {
        let i = 0;
        if (isAirAtlanta(this.state.COMPANYNAME)) {
            const nocRosterMonths = await fetchNocRosterMonths(this.state);
            const people = await whenActiveMhcPeople;
            for (const person of people) {
                this.setState({
                    peopleInitiated: ++i,
                });
                if (!isAaiFlightDeck(person) && !isAaiCabinCrew(person) && !isAaiLdm(person) && !isAaiMtx(person)) {
                    continue; // only flight deck, mtx and cabin crew are supported for now
                }
                const journalRecords = await this.calculateFeesForAaiPerson(person, nocRosterMonths);
                this.setState(prevState => ({
                    calculatedPeople: [
                        ...prevState.calculatedPeople,
                        { person, journalRecords },
                    ],
                }));
            }
        } else if (isLeonGraphqlCompany(this.state.COMPANYNAME)) {
            const whenLeonPeople = api.Roster.getLeonGraphqlPeople({ company: this.state.COMPANYNAME });
            const navisioned = new Set<CREWCODE>();
            const people = await whenActiveMhcPeople;
            for (const person of people) {
                navisioned.add(toCrewCodeUc(person.navEntry.No_));
                this.setState({
                    peopleInitiated: ++i,
                });
                const journalRecords = await this.calculateFeesForLeonGraphqlPerson(person.navEntry.No_);
                if (journalRecords.length === 0) {
                    journalRecords.push({
                        fee_entry_type: brand<FeeEntryType>(9000),
                        description: "Fix Manually - No Fees from Calculation",
                        units: "1",
                        payment_per_unit: "0",
                        service_start_time: getMonthStartDate(this.yearMonth),
                        service_end_time: getMonthEndDate(this.yearMonth),
                        crew_code: person.navEntry.No_,
                    });
                }
                this.setState(prevState => ({
                    calculatedPeople: [
                        ...prevState.calculatedPeople,
                        { person, journalRecords },
                    ],
                }));
            }
            for (const leonPerson of await whenLeonPeople) {
                if (navisioned.has(toCrewCodeUc(leonPerson.code))) {
                    continue;
                }
                this.setState({
                    peopleInitiated: ++i,
                });
                const journalRecords = await this.calculateFeesForLeonGraphqlPerson(leonPerson.code);
                let department = "";
                if (this.state.COMPANYNAME === "HEL - FKHUSD") {
                    if (leonPerson.role?.name === "Crew") {
                        department = "630";
                    }
                }
                const person: MinPersonMatch = {
                    navEntry: {
                        No_: leonPerson.code,
                        GlobalDimension1Code: department,
                    },
                };
                this.setState(prevState => ({
                    calculatedPeople: [
                        ...prevState.calculatedPeople,
                        { person, journalRecords },
                    ],
                }));
            }
        } else {
            const people = await whenActiveMhcPeople;
            for (const person of people) {
                this.setState({
                    peopleInitiated: ++i,
                });
                const personification = {
                    crew_code: person.navEntry.No_,
                };
                const journalRecords = (await this.calculateFeesForManualInvoicePerson(person.navEntry.No_))
                    .map(jr => ({ ...jr, ...personification }));
                this.setState(prevState => ({
                    calculatedPeople: [
                        ...prevState.calculatedPeople,
                        { person, journalRecords },
                    ],
                }));
            }
        }
    }

    private get yearMonth(): AbsoluteMonth {
        return this.state;
    }

    private async calculateFeesForManualInvoicePerson(employeeCode: CrewCode) {
        const journalRecords = [];
        const sheetData = await api.Invoice.GetManualInvoiceByNavLocator({
            year: this.state.year,
            company: this.state.COMPANYNAME,
            month: this.state.month,
            employeeCode: employeeCode,
        });
        if (sheetData && sheetData.CalculatedJournalRecords) {
            journalRecords.push(...sheetData.CalculatedJournalRecords);
        } else {
            journalRecords.push(...fixManually(this.state, "Work Report not Submitted"));
        }
        return journalRecords;
    }

    private async calculateFeesForAaiPerson(
        person: PersonMatch, nocRosterMonths: MonthStrToCrewMap
    ): Promise<CodedSalaryJournalRecord[]> {
        const employeeCode = toCrewCodeUc(person.navEntry.No_);
        if (person.navEntry.Status) {
            console.log("skipping an inactive person:: " + employeeCode + " " + person.navEntry.Status);
            return []; // inactive
        }
        const rosterLocator: PersonMonthRosterLocator = {
            ...person,
            ...this.yearMonth,
        };
        const personification = {
            crew_code: employeeCode,
        };
        const journalRecords: ImpersonalSalaryJournalRecord[] = [];
        if (!person.rdbEntry) {
            journalRecords.push(...fixManually(rosterLocator, "RDB Missing"));
        }
        const latestContractData = !person.rdbEntry ? null : await api.Contract.GetLatestContractData({
            rdbPersonId: person.rdbEntry.ApplicantID,
        }).catch(error => {
            if (error instanceof HttpResponseError && error.response.status === 404) {
                return null;
            } else {
                throw error;
            }
        });
        const rotationDays = getRotationDays({
            ...this.yearMonth,
            company: this.state.COMPANYNAME,
            employeeCode,
        }, nocRosterMonths);
        if (!rotationDays) {
            return fixManually(rosterLocator, "Roster Unavailable")
                .map(jr => ({ ...jr, ...personification }));
        }
        const params = { ...rosterLocator, latestContractData, rotationDays };
        if (isAaiFlightDeck(person)) {
            const officerMapping = await getAaiOfficerFlightDeckFeesMapping(this.state.year, this.state.month);
            const officerFees = officerMapping[employeeCode] ?? null;
            const precedingUnterminatedRotationDays = await getPrecedingUnterminatedRotationDays({
                employeeCode: toCrewCodeUc(person.navEntry.No_),
                company: this.state.COMPANYNAME,
                year: this.state.year,
                month: this.state.month,
            }, nocRosterMonths);
            const fdParams = {
                ...params,
                aircraft: this.state.COMPANYNAME === "D2W - AAIUSD"
                    ? person.navEntry.GlobalDimension2Code
                    : person.navEntry.ShortcutDimension3Code,
                precedingUnterminatedRotationDays,
            };
            const calculated = calculateFeesForFlightDeckPerson(fdParams, officerFees);
            if (calculated.length === 0) {
                calculated.push({
                    fee_entry_type: brand<FeeEntryType>(9000),
                    description: "Fix Manually - No Fees from Calculation",
                    units: "1",
                    payment_per_unit: "0",
                    service_start_time: getMonthStartDate(this.yearMonth),
                    service_end_time: getMonthEndDate(this.yearMonth),
                });
            }
            journalRecords.push(...calculated);
        } else if (isAaiCabinCrew(person)) {
            journalRecords.push(...calculateFeesForCabinCrewPerson(params));
        } else if (isAaiLdm(person)) {
            const personFees = await api.Contract.GetPersonWageContractFees({
                employeeCode: employeeCode,
                month: this.state.month,
                year: this.state.year,
                company: this.state.COMPANYNAME,
            });
            journalRecords.push(...calculateFeesForLoadMasterPerson(params, personFees));
        } else if (isAaiMtx(person)) {
            journalRecords.push(...await this.calculateFeesForManualInvoicePerson(employeeCode));
        } else {
            journalRecords.push(...fixManually(params, "Unsupported department: " + person.navEntry.GlobalDimension1Code));
        }
        return journalRecords.map(jr => ({ ...jr, ...personification }));
    }

    private isCabinCrew(person: MinPersonMatch) {
        if (isAirAtlanta(this.state.COMPANYNAME)) {
            return isAaiCabinCrew(person);
        } else if (this.state.COMPANYNAME === "HEL - FKHUSD") {
            return person.navEntry.GlobalDimension1Code === "610";
        } else {
            return false;
        }
    }

    private isFlightDeck(person: MinPersonMatch) {
        if (isAirAtlanta(this.state.COMPANYNAME)) {
            return isAaiFlightDeck(person);
        } else if (this.state.COMPANYNAME === "HEL - FKHUSD") {
            return person.navEntry.GlobalDimension1Code === "630";
        } else {
            return false;
        }
    }

    private isMtx(person: MinPersonMatch) {
        if (isAirAtlanta(this.state.COMPANYNAME)) {
            return isAaiMtx(person);
        } else if (this.state.COMPANYNAME === "HEL - FKHUSD") {
            // not actually MTX, may also be OPS and such, but for time being
            // I just need a way to filter all of them out for demonstration
            return hasNoDepartment(person);
        } else {
            return false;
        }
    }

    private async insertJournalRecordsToNav() {
        this.setState({ loading: true });
        try {
            if (isAirAtlanta(this.state.COMPANYNAME)) {
                await api.Invoice.insert_salary_journal_records({
                    company: this.state.COMPANYNAME,
                    records: this.sortedCalculatedPeople
                        .filter(cp => this.isCabinCrew(cp.person))
                        .flatMap(cp => cp.journalRecords)
                        .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_CCM" })),
                });
                await api.Invoice.insert_salary_journal_records({
                    company: this.state.COMPANYNAME,
                    records: this.sortedCalculatedPeople
                        .filter(cp => this.isFlightDeck(cp.person))
                        .flatMap(cp => cp.journalRecords)
                        .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_FD" })),
                });
                // MTX salary journal entries are inserted on the moment of .xlsx submission
                // await api.Invoice.insert_salary_journal_records({
                //     company: this.state.COMPANYNAME,
                //     records: this.sortedCalculatedPeople
                //         .filter(cp => isMtx(cp.person))
                //         .flatMap(cp => cp.journalRecords)
                //         .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "ROST_MTX" })),
                // });
            } else if (this.state.COMPANYNAME === "F2R - ASTEUR"
                    || this.state.COMPANYNAME === "CRM - RPDEUR"
                    || this.state.COMPANYNAME === "HEL - FKHUSD"
            ) {
                await api.Invoice.insert_salary_journal_records({
                    company: this.state.COMPANYNAME,
                    records: this.sortedCalculatedPeople
                        .flatMap(cp => cp.journalRecords)
                        .map((r, i) => ({ ...r, id: (i + 1) * 1000, batch_name: "LEON_ROST" })),
                });
            } else {
                throw new Error("Unsupported company: " + this.state.COMPANYNAME);
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    private get sortedCalculatedPeople() {
        const filtered = this.state.calculatedPeople.filter(cp => {
            if (!this.state.includeCabinCrew && this.isCabinCrew(cp.person)) {
                return false;
            }
            if (!this.state.includeLdm && isAaiLdm(cp.person)) {
                return false;
            }
            if (!this.state.includeFlightDeck && this.isFlightDeck(cp.person)) {
                return false;
            }
            if (!this.state.includeMtx && this.isMtx(cp.person)) {
                return false;
            }
            if (!this.state.includeNoDepartment && hasNoDepartment(cp.person)) {
                return false;
            }
            return true;
        });
        return filtered.sort((a,b) => {
            const aFix = a.journalRecords.find(jr => jr.fee_entry_type === 9000);
            const bFix = b.journalRecords.find(jr => jr.fee_entry_type === 9000);
            if (aFix && bFix) {
                // put "Fix Manually" records without extra units lines in the beginning
                if (a.journalRecords.length === 1 && b.journalRecords.length > 1) {
                    return -1;
                } else if (b.journalRecords.length === 1 && a.journalRecords.length > 1) {
                    return 1;
                } else {
                    return strcmp(aFix.description, bFix.description);
                }
            } else if (aFix) {
                return -1;
            } else if (bFix) {
                return 1;
            }
            const aHasZeros = a.journalRecords.some(jr => +jr.units === 0 || +jr.payment_per_unit === 0);
            const bHasZeros = b.journalRecords.some(jr => +jr.units === 0 || +jr.payment_per_unit === 0);
            if (aHasZeros && !bHasZeros) {
                return -1;
            } else if (bHasZeros && !aHasZeros) {
                return 1;
            }
            const aNotes = getOfficerNotesText(this.state.officerFlightDeckFeesMapping ?? {}, a.person);
            const bNotes = getOfficerNotesText(this.state.officerFlightDeckFeesMapping ?? {}, b.person);
            if (aNotes && !bNotes) {
                return -1;
            } else if (bNotes && !aNotes) {
                return 1;
            }
            return 0;
        });
    }

    render() {
        return <div>
            <h1>ROST_FD/ROST_CCM Salary Journal batch generated from Roster API</h1>
            <p>On this page you can re-calculate Salary Journal from current roster</p>
            <header>
                <div className="status-data-row">
                    <span>{this.state.COMPANYNAME}</span>
                </div>
                <div className="action-buttons">
                    <span>{this.state.peopleInitiated} / {this.state.totalPeople ?? "?"}</span>
                    <button className="submit-to-nav" disabled={this.state.loading} onClick={() => this.insertJournalRecordsToNav()}>Submit ROST_FD/CCM to NAV</button>
                </div>
                <div className="filters-row">
                    <label>
                        <span>Flight Deck</span>
                        <input type="checkbox" checked={this.state.includeFlightDeck} onChange={e => this.setState({ includeFlightDeck: e.target.checked })}/>
                    </label>
                    <label>
                        <span>Cabin Crew</span>
                        <input type="checkbox" checked={this.state.includeCabinCrew} onChange={e => this.setState({ includeCabinCrew: e.target.checked })}/>
                    </label>
                    <label>
                        <span>LDM</span>
                        <input type="checkbox" checked={this.state.includeLdm} onChange={e => this.setState({ includeLdm: e.target.checked })}/>
                    </label>
                    <label>
                        <span>MTX</span>
                        <input type="checkbox" checked={this.state.includeMtx} onChange={e => this.setState({ includeMtx: e.target.checked })}/>
                    </label>
                    <label>
                        <span>No Dep.</span>
                        <input type="checkbox" checked={this.state.includeNoDepartment} onChange={e => this.setState({ includeNoDepartment: e.target.checked })}/>
                    </label>
                </div>
            </header>
            <NavJournalRecordsTable
                COMPANYNAME={toCOMPANYNAMEUC(this.state.COMPANYNAME)}
                calculatedPeople={this.sortedCalculatedPeople}
                codeToNavInvoiceItems={this.state.codeToNavInvoiceItems}
                codeToOfficerNotes={this.state.officerFlightDeckFeesMapping ?? {}}
                makeLink={jr => "/Home/FeeStatementInvoiceConfirmation?" + new URLSearchParams({
                    company: this.invoicedInAirborne ? "Airborne - Malta" : this.state.COMPANYNAME,
                    year: String(this.yearMonth.year),
                    month: String(this.yearMonth.month),
                    employeeCode: jr.crew_code,
                })}
            />
        </div>;
    }
}
