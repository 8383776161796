
import { confirmAlert } from "react-confirm-alert";
import Pagination from "react-js-pagination";
import * as React from "react";
import * as _ from "lodash";
import type { Dispatch } from "redux";
import { typed } from "../utils/typing";
import type { ClaimAction } from "../types/Claim";
import { stringifyError } from "../utils/errorHandling";
import { getMonthFullName } from "../utils/dates";
import type { CompanyName } from "../features/Api";
import api from "../features/Api";
import { getClaimDateClass, getLatestComment, getSoeClaimStatusClass } from "../features/soeUtils";
import ClaimActionCard from "../components/ClaimActionCard";

type Props = {
  code: string, // Employee.No_
  id: number, // Employee.Id
  email: string, // Employee.EMail
  company: CompanyName, // Employee.CompanyName
  claimsPerPage: string,
  dispatch: Dispatch,
};

function getInitialState(claimsPerPage: string) {
  const today = new Date();
  const soeContractorDashboardStatusFilter = sessionStorage.getItem("soeContractorDashboardStatusFilter");
  const sessionStatus = !soeContractorDashboardStatusFilter ? 99 : parseInt(soeContractorDashboardStatusFilter);
  return {
    nameDeptCrewcode: "",
    claimStatus: sessionStatus,
    claimCategory: sessionStorage.getItem("soeContractorDashboardCategoryFilter") ?? "",
    claimMonth: sessionStorage.getItem("soeContractorDashboardMonthFilter") ?? "",
    sortColumn: "",
    sortAscending: true,
    claimCompany: 1,
    fromDate: new Date().setDate(today.getDate() - 90),
    toDate: today,
    currentPage: 1,
    claimsPerPage: parseInt(claimsPerPage),
    claimActions: typed<undefined | ClaimAction[]>(undefined),
    ClaimsPerPage: typed<undefined | number>(undefined),
  };
}

type State = ReturnType<typeof getInitialState>;

type ClaimActionColumn = (keyof ClaimAction) | "DateOfTravel" | "DateOfReceipt";

export default class ClaimsDashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    require("../components/ClaimActionCard.css");
    require("./ClaimsDashboard.css");
    this.state = getInitialState(this.props.claimsPerPage);
  };

  componentDidMount() {
    api.Claim.GetClaimsForDashboard().then(claimActions => this.setState({ claimActions }));
  };

  _getClaimStatuses() {
    const self = this;
    const statuses = _.uniqBy(self.state.claimActions, "Status");
    const data = _.map(_.sortBy(statuses, "StatusStr"), function (status) {
      return <option key={status.Status} value={status.Status}>{status.StatusStr}</option>;
    });

    const option = <option key={99} value={99}>All</option>;
    data.push(option);
    return data;
  };

  _getClaimCategories() {
    const self = this;
    const categories = _.uniqBy(self.state.claimActions, "Category");
    const data = _.map(_.sortBy(categories, "Category"), function (category) {
      return <option key={category.Category} value={category.Category}>{category.Category}</option>;
    });

    const option = <option key="allCategory" value="">All</option>;
    data.push(option);
    return data;
  };

  _getSoeLast6Months() {
    const self = this;
    const arrLast6Months = [];
    //Get the current year.
    const currentYear = new Date().getFullYear(); //E.g. 2021
    //Get current month
    let currentMonth = (new Date().getMonth()); //January has the value 0.

    //Do the past 6 months extend into the last year?
    let iDiff = currentMonth - 11;
    const iYears = iDiff < 0 ? 1 : 0;

    //The years
    for (let iYear = currentYear; iYear >= currentYear - iYears; iYear--) {
      //Get months for each year.
      for (let iMonth = currentMonth; iMonth > iDiff; iMonth--) {
        //Value
        let sMonthKey: string | number = iMonth + 1;
        sMonthKey = sMonthKey.toString().length == 1 ? "0" + sMonthKey.toString() : sMonthKey.toString();
        const sKey = iYear.toString() + sMonthKey.toString();

        const sDisplayText = iYear.toString() + " - " + self._getMonthName(iMonth);
        arrLast6Months.push(<option key={sKey} value={sKey}>{sDisplayText}</option>);

        //End first year.
        if (iMonth == 0) {
          //For the second year. Start in December.
          currentMonth = 11;
          iDiff = iDiff + 11;
          break;
        }
      }
    }
    const option = <option key="allMonths" value="">All</option>;
    arrLast6Months.push(option);
    return arrLast6Months;
  };

  _getMonthName(month: number) {
    return getMonthFullName(month + 1);
  };

  _getClaimsPerPage() {
    const data = [];
    for (let i = parseInt(this.props.claimsPerPage); i <= 100; i += 10) {
      data.push(<option key={i} value={i}>{i}</option>);
    }
    return data;
  };

  _filterByClaimStatus(e: React.ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem("soeContractorDashboardStatusFilter", e.target.value);
    this.setState({ claimStatus: parseInt(e.target.value), currentPage: 1 });
  };

  _filterByClaimCategory(e: React.ChangeEvent<HTMLSelectElement>) {
    sessionStorage.setItem("soeContractorDashboardCategoryFilter", e.target.value);
    this.setState({ claimCategory: e.target.value, currentPage: 1 });
  };

  _filterByMonth(e: React.ChangeEvent<HTMLSelectElement>) {
    const monthFilter = e.target.value ? e.target.value :  "";
    sessionStorage.setItem("soeContractorDashboardMonthFilter", monthFilter);
    this.setState({ claimMonth: monthFilter, currentPage: 1 });
  };

  _changeClaimsPerPage(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ ClaimsPerPage: parseInt(e.target.value) });
  };

  _generateFilters() {
    const col = 4;
    return (
      <div className="filterContainer">
        <div className={"col-xs-" + col + " select"}>
          <span>Status </span>
          <select className="widerSelect" onChange={(e) => this._filterByClaimStatus(e)} value={this.state.claimStatus}>
            {this._getClaimStatuses()}
          </select>
        </div>
        <div className={"col-xs-" + col + " select"}>
          <span>Month </span>
          <select className="widerSelect" value={this.state.claimMonth}
                  onChange={(e) => this._filterByMonth(e)}>
            {this._getSoeLast6Months()}
          </select>
        </div>
        <div className={"col-xs-" + col + " select"}>
          <span>Category </span>
          <select className="widerSelect" onChange={(e) => this._filterByClaimCategory(e)} value={this.state.claimCategory}>
            {this._getClaimCategories()}
          </select>
        </div>
      </div>
    );
  };

  _generateClaimsPerPage(total: number) {
    return (
      <div className="row pagination">
        <select onChange={(e) => this._changeClaimsPerPage(e)} value={this.state.claimsPerPage}>
          {this._getClaimsPerPage()}
        </select>
        <span> of {total}</span>
      </div>
    );
  };

    _confirmDelete(soeId: number) {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure you want to delete",
            buttons: [
                {
                    label: "Delete",
                    onClick: () => {
                        this.deleteSoe(soeId);
                    }
                },
                {
                    label: "Cancel",
                    onClick: () => {}
                }
            ]
        });
    };

  handlePageChange(pageNumber: number) {
    this.setState({ currentPage: pageNumber });
  };

  deleteSoe = (soeId: number) => {
    api.Claim.DeleteSoe({ soeId })
      .then(() => {
        let claimActions = this.state.claimActions;
        if (claimActions) {
          claimActions = claimActions.filter(claim => claim.ClaimId !== soeId);
          this.setState({ claimActions: claimActions });
        }

        toastr.success("SoE deleted");
      }).catch((error: unknown) => {
      console.error(error);
      toastr.warning("Could not delete SoE: " + stringifyError(error));
    });
  };

  sortBy(e: React.MouseEvent<HTMLTableCellElement>) {
    const sortByValue = e.currentTarget.attributes["data-id"].value;
    if (this.state.sortColumn === sortByValue) {
      this.setState({ sortAscending: !this.state.sortAscending });
    } else {
      this.setState({ sortAscending: true });
    }
    this.setState({ sortColumn: sortByValue });
    const sortByDirection = this.state.sortAscending ? "asc" : "desc";
    const tempClaimActions = _.orderBy<ClaimAction>(this.state.claimActions, [sortByValue], [sortByDirection]);
    this.setState({ claimActions: tempClaimActions });
  };

  filterByMonth(filteredData: ClaimAction[]) {
    if (this.state.claimMonth === "") return filteredData;
    filteredData = filteredData.filter(item => item.DateSubmittedStr.split(".")[1] === this.state.claimMonth.substring(4, 6));
    filteredData = filteredData.filter(item => item.DateSubmittedStr.split(".")[2] === this.state.claimMonth.substring(0, 4));

    return filteredData;
  };

  getSortClasses(column: ClaimActionColumn) {
    let className = "";
    if (column === "EmployeeCode") {
      className = " sort";
    } else if (column === "Status") {
      className = " sort";
    } else if (column === "Comments") {
      className = " sort";
    } else if (column === "Category") {
      className = " sort";
    } else if (column === "Amount") {
      className = " sort";
    } else if (column === "Currency") {
      className = " sort";
    } else if (column === "DateOfReceipt") {
      className = " sort";
    }
    if (this.state.sortColumn !== column) {
      return className;
    }
    return (this.state.sortAscending ? "ascending" : "descending") + className;
  };

  render() {
      if (!this.state.claimActions) {
        return <div className="status-loading-animated-ellipsis claims-dashboard-data-loading-message">Loading</div>;
      }
      let filteredData: ClaimAction[] = this.state.claimActions;
      // filteredData = _.filter(this.state.claimActions, {'CompanyId' : this.props.claims.claimCompany });
      if (this.state.claimStatus !== 99)
        filteredData = filteredData.filter(ca => ca.Status === this.state.claimStatus);

      if (this.state.claimCategory !== "")
        filteredData = filteredData.filter(ca => ca.Category === this.state.claimCategory);

      if (this.state.claimMonth !== "99" && filteredData !== null)
        filteredData = this.filterByMonth(filteredData);

      filteredData = _.filter(filteredData, d => d.EmployeeName.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()) || d.EmployeeCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase()) || (d.DepartmentCode != null && d.DepartmentCode.toUpperCase().includes(this.state.nameDeptCrewcode.toUpperCase())));

      const indexOfLastClaim = this.state.currentPage * this.state.claimsPerPage;
      const indexOfFirstClaim = indexOfLastClaim - this.state.claimsPerPage;
      const currentClaims = filteredData ? filteredData.slice(indexOfFirstClaim, indexOfLastClaim) : null;
      const claimActions = currentClaims ? currentClaims.map((claimAction, index) => {
        return (<ClaimActionLine claimAction={claimAction}
                                 key={index}
                                 dispatch={this.props.dispatch}
                                 deleteSoe={this._confirmDelete}
                />);
      }) : [];

      const pageNumbers = [];

      if (filteredData) {
        for (let i = 1; i <= Math.ceil(filteredData.length / this.state.claimsPerPage); i++) {
          pageNumbers.push(i);
        }
      }
      if (claimActions.length === 0) {
        return (<div className="invoice-wrapper">
            <div className="invoice-header">
              <div className="container">
                <div className="row">
                  <div className="col-xs-3 header">
                    <h1>All SoE</h1>
                  </div>
                  <div className="col-xs-9 filters">
                    {this._generateFilters()}
                  </div>

                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="soeBar">
                    <a href="/Home/Claim" className="btn btn-primary">Create SoE</a>
                  </div>
                  <h1>{"No SoE data found."}</h1>
                </div>
              </div>
            </div>

          </div>
        );
      } else {
        return (
          <div className="invoice-wrapper">
            <div className="invoice-header">
              <div className="container">
                <div className="row">
                  <div className="col-xs-3 header">
                    <h1>All SoE</h1>
                  </div>
                  <div className="col-xs-9 filters">
                    {this._generateFilters()}
                  </div>
                </div>
              </div>
            </div>
            <div className="soe-container container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="soeBar">
                    <a href="/Home/Claim" className="btn btn-primary">Create SoE</a>
                  </div>
                  <div className="soe-table table-responsive">
                      <table className="invoices table table-striped table-condensed">
                          <thead>
                              <tr>
                                  <th className="noSort"/>
                                  <th className={this.getSortClasses("DateOfReceipt")} data-id={"Date"}
                                      onClick={e => this.sortBy(e)}>Date of Receipt
                                  </th>
                                  <th className={this.getSortClasses("Currency")} data-id={"Currency"}
                                      onClick={e => this.sortBy(e)}>Currency
                                  </th>
                                  <th className={this.getSortClasses("Amount")} data-id={"Amount"}
                                      onClick={e => this.sortBy(e)}>Amount
                                  </th>
                                  <th className={this.getSortClasses("Category")} data-id={"Category"}
                                      onClick={e => this.sortBy(e)}>Category
                                  </th>
                                  <th className={this.getSortClasses("Status")} data-id={"Status"}
                                      onClick={e => this.sortBy(e)}>Status
                                  </th>
                                  <th className={this.getSortClasses("Comments")} data-id={"Comments"}
                                      onClick={e => this.sortBy(e)}>Last comments
                                  </th>
                                  <th data-id={"DateSubmitted"}
                                      onClick={e => this.sortBy(e)}>Submitted on
                                  </th>
                                  <th data-id={"DateModified"}
                                      onClick={e => this.sortBy(e)}>Last Updated on
                                  </th>
                                  <th data-id={"ApproverName"}
                                      onClick={e => this.sortBy(e)}>Approver
                                  </th>
                            </tr>
                          </thead>
                          <tbody>
                              {claimActions}
                          </tbody>
                      </table>
                      <div className="soe-mobile-container">
                          {(currentClaims || []).map((claimAction) => {
                              return (
                                  <ClaimActionCard
                                      key={claimAction.ClaimId}
                                      claimAction={claimAction}
                                      isSubmitter={true}
                                      deleteAction={this._confirmDelete}
                                  />
                              );
                          })}
                      </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-2" hidden={true}>
                  {this._generateClaimsPerPage(filteredData ? filteredData.length : 0)}
                </div>
                <div className="col-xs-12 text-right">
                  <Pagination
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.state.claimsPerPage}
                    totalItemsCount={filteredData ? filteredData.length : 0}
                    pageRangeDisplayed={5}
                    onChange={(e) => this.handlePageChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
}

type ClaimActionLineProps = {
  claimAction: ClaimAction,
  dispatch: Dispatch,
  deleteSoe: (id: number) => void,
};

class ClaimActionLine extends React.Component<ClaimActionLineProps, {}> {
  constructor(props: ClaimActionLineProps) {
    super(props);
    this.state = {};
  }

  render() {
    const claimAction = this.props.claimAction;
    const claimStatusClass = getSoeClaimStatusClass(claimAction.Status);
    const claimDateClass = getClaimDateClass(claimAction.Date);

    return (<tr>
      {
        this.props.claimAction.Status === 3 || this.props.claimAction.Status === 0 ?
          <td>
            <a data-id={claimAction.ClaimId} href={"/Home/UpdateClaim?claimId=" + claimAction.ClaimId}>
              <img data-id={claimAction.ClaimId} src="/img/edit.svg"/>
            </a>&nbsp;
            <a onClick={() => this.props.deleteSoe(claimAction.ClaimId)}>
              <img data-id={claimAction.ClaimId} src="/img/delete.svg" className={"halfOpacity"}/>
            </a>
          </td>
        :
          <td>
            <a data-id={claimAction.ClaimId} href={"/Home/UpdateClaim?claimId=" + claimAction.ClaimId}>
              <img data-id={claimAction.ClaimId} className={"halfOpacity"} src="/img/view.png"/>
            </a>
          </td>
      }
      <td><span className={claimDateClass}>{claimAction.DateStr}</span></td>
      <td>{claimAction.Currency}</td>
      <td>{claimAction.Amount}</td>
      <td>{claimAction.Category}</td>
      <td className="text-center"><span className={claimStatusClass}>{claimAction.StatusStr}</span></td>
      <td>{getLatestComment(claimAction.Comments)}</td>
      <td>{claimAction.DateSubmittedStr}</td>
      <td>{claimAction.DateModifiedStr}</td>
      <td>{claimAction.ApproverName}</td>
    </tr>);
  }
}