
import * as React from "react";
import type { Workbook } from "exceljs";
import { stringifyError } from "../utils/errorHandling";

import {
    fillTemplateFromRoster,
    getBlankTemplate,
    getIsMtx1, getTemplateDataLink
} from "../features/manual-work-invoices/aaiMtxXlsxWorkInvoiceFiller";

import type { AddManualInvoiceFromSheetRequest } from "../features/Api";
import api from "../features/Api";
import { parseWorkInvoiceXlsx } from "../features/manual-work-invoices/aaiMtxXlsxWorkInvoiceParser";
import type { RaidoPersonMonthRoster, SalaryJournalRecord } from "../features/ExternalRostersApi";
import externalRostersApi from "../features/ExternalRostersApi";
import NavJournalRecordsTable from "./NavJournalRecordsTable";
import type { Mtx1Fields, NormalFields,PlainMatrix
} from "../features/manual-work-invoices/aaiMtxIntegratedSheetInput";
import {
    fillRowFromData,
    fillRowsFromRoster,
    getMtx1Fields, getNormalFields,
    parseRow
} from "../features/manual-work-invoices/aaiMtxIntegratedSheetInput";
import { getMonthDate } from "../utils/dates";
import ActionsQueue from "../utils/ActionsQueue";
import type { AaiMtxManualInvoiceDayFromSheet } from "../types/Api/Invoice";
import type { EmployeeInfoWise } from "../types/EmployeeInfo";
import ManualInvoiceAaiMtxSubmissionFormInputTable from "./ManualInvoiceAaiMtxSubmissionFormInputTable";
import type { ManualInvoiceSubmissionFormProps } from "../features/manual-work-invoices/sheetUtils";
import { initializeEmptyRows } from "../features/manual-work-invoices/sheetUtils";
import { toCOMPANYNAMEUC, toCrewCodeUc, toUpperCase } from "../utils/typing";
import { raidoToRm } from "../features/roster/air_atlanta/RotationDaysGrouper";

declare global {
    interface Window {
        WHEN_EXCELJS: Promise<{ Workbook: typeof Workbook }>,
    }
}

type State = {
    prefilledTemplateLink: string | null,
    rosterError: string | null,
    loading: boolean,
    submitting: boolean,
    hasUnsavedChanges: boolean,
    showExcelButtons: boolean,
    journalRecords: null | SalaryJournalRecord[],
    journalRecordsLoading: boolean,
    journalRecordsError: unknown,
    commentBoxText: string,
    resetDayRowsKey: Symbol,
    resetDayRows: PlainMatrix,
};

async function readFile(file: File) {
    if (!file.name.toLowerCase().endsWith(".xlsx")) {
        throw new Error("File extension must be .xlsx, not ." + file.name.replace(/.*\./, ""));
    }
    const buff: ArrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = () => reject(reader.error ?? new Error("Failed to read the file"));
        reader.readAsArrayBuffer(file);
    });
    return buff;
}

async function interpretXlsxFile(buff: ArrayBuffer) {
    const ExcelJS = await window.WHEN_EXCELJS;
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buff);
    return workbook;
}

function getInvoiceDayFields(profile: EmployeeInfoWise): NormalFields | Mtx1Fields {
    if (getIsMtx1(profile)) {
        return getMtx1Fields();
    } else {
        return getNormalFields();
    }
}

function css(strings: TemplateStringsArray, ...parameters: unknown[]) {
    let result = strings[0];
    for (let i = 0; i < parameters.length; ++i) {
        result += parameters[i] + strings[i + 1];
    }
    return result;
}

const INPUT_TABLE_BODY_SELECTOR = ".manual-invoice-aai-mtx-submission-form .Spreadsheet__table > tbody";
const ERROR_CELL_COLOR = "rgb(255, 189, 174)";

function updateDynamicStyling(dynamicStyling: HTMLStyleElement, days: AaiMtxManualInvoiceDayFromSheet[]) {
    let resultingCss = "";
    for (let i = 0; i < days.length; ++i) {
        const day = days[i];
        let backgroundColor;
        if (!day.PaymentOnOff) {
            backgroundColor = "var(--activity-off-duty-bg-color)";
        } else if (day.Flying) {
            backgroundColor = "var(--activity-flight-duty-bg-color)";
        } else if (!day.Base.includes("TRAVEL")) {
            backgroundColor = "var(--activity-on-duty-bg-color)";
        }
        if (!backgroundColor) {
            continue;
        }
        resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td:not(:hover) {
            background-color: ${backgroundColor};
        }`;

        if (day.OverTime && !day.OverTime.match(/^\d{1,2}(:\d{1,2})?$/)) {
            resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td.field-name--OverTime {
                background-color: ${ERROR_CELL_COLOR};
            }`;
        }
        if (day.TimeIn1?.length > 10) {
            resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td.field-name--TimeIn1 {
                background-color: ${ERROR_CELL_COLOR};
            }`;
        }
        if (day.TimeIn2?.length > 10) {
            resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td.field-name--TimeIn2 {
                background-color: ${ERROR_CELL_COLOR};
            }`;
        }
        if (day.TimeOut1?.length > 10) {
            resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td.field-name--TimeOut1 {
                background-color: ${ERROR_CELL_COLOR};
            }`;
        }
        if (day.TimeOut2?.length > 10) {
            resultingCss += css`${INPUT_TABLE_BODY_SELECTOR} > tr[row="${i}"] > td.field-name--TimeOut2 {
                background-color: ${ERROR_CELL_COLOR};
            }`;
        }
    }
    if (dynamicStyling.textContent !== resultingCss) {
        dynamicStyling.textContent = resultingCss;
    }
}

type Props = ManualInvoiceSubmissionFormProps;

export default class ManualInvoiceAaiMtxSubmissionForm extends React.Component<Props, State> {
    private latestDayRows: PlainMatrix;
    private feeCalculationsQueue = ActionsQueue();

    constructor(props: Props) {
        require("./ManualInvoiceAaiMtxSubmissionForm.css");
        super(props);
        this.latestDayRows = initializeEmptyRows(
            getInvoiceDayFields(props.profile), this.props
        );
        this.state = {
            prefilledTemplateLink: null,
            rosterError: null,
            loading: true,
            submitting: false,
            hasUnsavedChanges: false,
            showExcelButtons: false,
            journalRecords: null,
            journalRecordsLoading: false,
            journalRecordsError: null,
            commentBoxText: "",
            resetDayRowsKey: Symbol(),
            resetDayRows: this.latestDayRows,
        };
        const rosterLocator = {
            crew_code: toCrewCodeUc(this.employeeCode),
            year: props.year,
            month: props.month,
        };
        const whenRoster = externalRostersApi.getAirAtlantaRaidoRoster(rosterLocator);
        const whenExcelWorkbook = window.WHEN_EXCELJS
            .then(ExcelJS => getBlankTemplate(props, props.profile, ExcelJS))
            .then(async workbook => {
                const prefilledTemplateLink = await getTemplateDataLink(workbook);
                this.setState({ prefilledTemplateLink });
                return workbook;
            });
        whenRoster.then(async nocRosterCrew => {
            const personMonthRoster: RaidoPersonMonthRoster = {
                ...rosterLocator,
                roster_complete: raidoToRm(nocRosterCrew, rosterLocator),
                roster_incomplete: [],
                retrieved_time: new Date().toISOString(),
            };
            const rosterWithExtraData = {
                profileNav: this.props.profile, personMonthRoster,
            };
            const newRows = fillRowsFromRoster(this.latestDayRows, rosterWithExtraData);
            this.resetInputTable(newRows, true);
            const workbook = await whenExcelWorkbook;
            fillTemplateFromRoster(workbook.worksheets[0], personMonthRoster, this.props.profile);
            const prefilledTemplateLink = await getTemplateDataLink(workbook);
            this.setState({ prefilledTemplateLink });
        }).catch(error => this.setState({
            rosterError: stringifyError(error),
        })).finally(() => this.setState({ loading: false }));

        window.addEventListener("beforeunload", event => {
            if (this.state.hasUnsavedChanges) {
                (async () => {
                    const submitButton = document.getElementById("submit-entered-sheet-data-btn");
                    if (submitButton) {
                        submitButton.focus();
                        submitButton.scrollIntoView();
                    }
                })();
                event.preventDefault();
                event.returnValue = "dontcloseplizzzz";
                return "dontcloseplizzzz";
            }
        });
    }

    private get company() {
        return this.props.profile.CompanyName;
    }

    private get employeeCode() {
        return toUpperCase(this.props.profile.No_);
    }

    private get workInvoiceFileName() {
        return "Work Invoice MTX " +
            this.company + " " +
            this.props.year + "-" +
            String(this.props.month).padStart(2, "0") + " " +
            this.employeeCode;
    }

    private async uploadXlsxInvoiceFile(file: File) {
        this.setState({ submitting: true });
        try {
            const buff = await readFile(file);
            const workbook = await interpretXlsxFile(buff);
            const payload = parseWorkInvoiceXlsx(workbook, this.props, this.props.profile);
            const isMtx1 = getIsMtx1(this.props.profile);
            const newDayRows = payload.InvoiceDays.map(
                invoiceDay => fillRowFromData(invoiceDay, isMtx1)
            );
            this.resetInputTable(newDayRows, true);
            this.setState({
                commentBoxText: payload.CommentBox,
                hasUnsavedChanges: true,
            });
            const filledTable = document.querySelector(".work-report-input-table");
            if (filledTable instanceof HTMLElement) {
                filledTable.scrollIntoView();
            }
        } finally {
            this.setState({ submitting: false });
        }
    }

    private getSheetRequestData(): AddManualInvoiceFromSheetRequest<AaiMtxManualInvoiceDayFromSheet> {
        return {
            CommentBox: this.state.commentBoxText,
            InvoiceDays: this.latestDayRows.map((row, i) => {
                const ActivityDate = getMonthDate(this.props, i + 1);
                return parseRow(row, this.invoiceDayFields, ActivityDate);
            }),
            InvoiceId: this.props.invoiceId,
        };
    }

    private async submitEnteredData() {
        const sheetRequestData = this.getSheetRequestData();
        if (!sheetRequestData.InvoiceDays.some(id => id.PaymentOnOff) &&
            confirm("None of the days were marked as On Pay. Do you wish to submit empty report?") === false
        ) {
            return;
        }
        this.setState({ submitting: true });
        try {
            await api.Invoice
                .AddManualInvoiceFromSheet(sheetRequestData);
        } catch (error) {
            const errorTd = [...document.querySelectorAll(`${INPUT_TABLE_BODY_SELECTOR} > tr > td`)]
                // I'm a shit coder and I know it
                .find(td => td.computedStyleMap && td.computedStyleMap().get("background-color")?.toString() === ERROR_CELL_COLOR);
            if (errorTd) {
                errorTd.scrollIntoView();
            }
            throw error;
        } finally {
            this.setState({ submitting: false });
        }
        this.props.onSubmitted(sheetRequestData);
        this.setState({ hasUnsavedChanges: false });
    }

    private get invoiceDayFields() {
        return getInvoiceDayFields(this.props.profile);
    }

    private async setStateAsync<K extends keyof State>(newState: Pick<State, K>) {
        return new Promise<void>(resolve => this.setState(newState, resolve));
    }

    private resetInputTable(newRows: PlainMatrix, changeFromParent = false) {
        this.latestDayRows = newRows;
        if (changeFromParent) {
            this.setState({ resetDayRows: newRows, resetDayRowsKey: Symbol() });
        } else {
            this.setState({ resetDayRows: newRows });
        }
        this.requestPreliminaryFeesRecalculation();
        this.requestDynamicStylingUpdate();
    }

    private lastFeesCalculationRequest: Promise<void> | null = null;

    private requestPreliminaryFeesRecalculation() {
        const sheetRequestData = this.getSheetRequestData();
        const scopeRequest = this.feeCalculationsQueue.enqueue(async () => {
            if (scopeRequest !== this.lastFeesCalculationRequest) {
                return; // cancel outdated request that was overrun by a newer one
            }
            this.setState({ journalRecordsLoading: true });
            try {
                const { journalRecords } = await api.Invoice
                    .CalculateFeesForSheet(sheetRequestData);
                this.setState({ journalRecords, journalRecordsError: null });
            } catch (error) {
                this.setState({ journalRecords: null, journalRecordsError: error });
            } finally {
                this.setState({ journalRecordsLoading: false });
            }
        });
        this.lastFeesCalculationRequest = scopeRequest;
    }

    private requestDynamicStylingUpdate() {
        const sheetRequestData = this.getSheetRequestData();
        const dynamicStyling = document.getElementById("react-spreadsheet-dynamic-styling");
        if (dynamicStyling instanceof HTMLStyleElement) {
            updateDynamicStyling(dynamicStyling, sheetRequestData.InvoiceDays);
        }
    }

    render() {
        return <div className="manual-invoice-aai-mtx-submission-form">
            <div className="manual-invoice-aai-mtx-submission-form-card">
                <div className="manual-invoice-aai-mtx-submission-form-body">
                    <div className="sheet-input-header-part">
                        <div className="informational-part">
                            <div className="please-enter-data-message">{this.state.prefilledTemplateLink
                                ? "Please, correct/add missing details (Overtime, Additional Days) and Submit"
                                : "Please, fill the work report table below and Submit"}</div>

                            {this.state.loading && <div className="status-loading-animated-ellipsis">Loading Roster Data</div>}
                            {this.state.submitting && <div className="status-loading-animated-ellipsis">Submitting the Work Report</div>}
                        </div>
                        <div className="comment-box-part">
                            <div className="comment-box-title-bar">
                                <strong>COMMENT BOX</strong>
                                <span>-for online fill-in-</span>
                            </div>
                            <textarea
                                rows={16} cols={42}
                                value={this.state.commentBoxText}
                                onChange={async event => {
                                    await this.setStateAsync({
                                        commentBoxText: event.target.value,
                                        hasUnsavedChanges: true,
                                    });
                                    this.requestPreliminaryFeesRecalculation();
                                }}
                            ></textarea>
                        </div>
                    </div>
                    <ManualInvoiceAaiMtxSubmissionFormInputTable
                        year={this.props.year}
                        month={this.props.month}
                        rowLabels={{
                            OverTime: getIsMtx1(this.props.profile) ? "Over 12.5 hours HH:MM" : "Over 12 hours HH:MM",
                        }}
                        invoiceDayFields={this.invoiceDayFields}
                        resetDayRowsKey={this.state.resetDayRowsKey}
                        resetDayRows={this.state.resetDayRows}
                        onExplicitChange={(rows) => {
                            this.setState({ hasUnsavedChanges: true });
                            this.resetInputTable(rows);
                        }}
                        onImplicitChange={(rows) => {
                            this.latestDayRows = rows;
                            this.setState({ hasUnsavedChanges: true });
                            this.requestPreliminaryFeesRecalculation();
                            this.requestDynamicStylingUpdate();
                        }}
                    />
                </div>
                <div className="buttons-list">
                    <div className="flex-remainder">
                        Total: <span style={{ whiteSpace: "nowrap" }}>$ {!this.state.journalRecords || this.state.journalRecordsLoading ? "?" :
                            this.state.journalRecords
                                .map(jr => +jr.payment_per_unit * +jr.units)
                                .reduce((a,b) => a + b, 0)
                                .toFixed(2)}</span> {this.state.commentBoxText.trim() ? " + Comment" : ""}
                    </div>
                    <button
                        id="submit-entered-sheet-data-btn"
                        disabled={this.state.submitting}
                        type="button"
                        className={this.state.hasUnsavedChanges ? "has-user-input" : undefined}
                        onClick={() => this.submitEnteredData()}
                    >Submit</button>
                </div>
            </div>
            {this.state.journalRecords && <div className={"preliminary-salary-journal-from-roster-section" + (this.state.journalRecordsLoading ? " recalculation-in-progress" : "")}>
                <h2>Preliminary Fees from Automation</h2>
                <p>These numbers are yet to be verified by the payroll officer and therefore are subject to change</p>
                <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                    <NavJournalRecordsTable calculatedPeople={[{
                        person: { navEntry: { No_: this.employeeCode } },
                        journalRecords: this.state.journalRecords,
                    }]} COMPANYNAME={toCOMPANYNAMEUC(this.company)}/>
                </div>
            </div>}
            {!!this.state.journalRecordsError && <div className="preliminary-salary-journal-from-roster-section-error">
                Failed to calculate preliminary fees: {stringifyError(this.state.journalRecordsError)}
            </div>}
            <div className="excel-submission-card">
                <h2
                    className={"excel-section-header" + (!this.state.showExcelButtons ? " click-to-expand" : "")}
                    onClick={() => this.setState({ showExcelButtons: true })}
                >Prefer to rather fill in Excel? <img src="/img/excel-logo.svg" className="excel-logo"/></h2>
                {this.state.showExcelButtons && <div className="buttons-list excel-file-buttons">
                    <a
                        href={this.state.prefilledTemplateLink ?? undefined}
                        download={this.workInvoiceFileName}
                    >Download Template File</a>
                    <div className="flex-remainder"></div>
                    <label className={this.state.submitting ? "disabled" : undefined}>
                        <span className="flex-remainder">
                            <img src="/img/upload_icon_white.svg"/>
                        </span>
                        <span>Upload Filled Template File</span>
                        <input
                            type="file"
                            disabled={this.state.submitting}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            value=""
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    this.uploadXlsxInvoiceFile(e.target.files[0]);
                                }
                            }}
                        />
                        <span className="flex-remainder"></span>
                    </label>
                </div>}
            </div>
        </div>;
    }
}