import type { ClaimAction } from "../types/Claim";
import { getDiffDays } from "../utils/Helper";

export function getSoeClaimStatusClass(status: ClaimAction["Status"]) {
    let claimStatusClass = "label ";
    switch (status) {
        case 0 :
            claimStatusClass += "label-primary";
            break;
        case 1 :
            claimStatusClass += "label-success";
            break;
        case 2 :
            claimStatusClass += "label-danger";
            break;
        case 3 :
            claimStatusClass += "label-info";
            break;
        case 5 :
            claimStatusClass += "label-success";
            break;
        default:
            claimStatusClass += "label-uncategorized";
            break;
    }
    return claimStatusClass;
}

export function getLatestComment(comments: string): string | null {
    if (comments !== null) {
        const splitComments = comments.split("\n");
        if (splitComments.length > 1) {
            return splitComments[0];
        }
        return comments;
    }

    return null;
}

export function getClaimDateClass(date: string): string | undefined {
    let claimDateClass;

    if (getDiffDays(new Date(), new Date(date)) > 90) {
        claimDateClass = "label label-warning";
    }

    return claimDateClass;
}
