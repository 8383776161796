import type { OfficerFlightDeckFees } from "../../../types/Api/Contract";
import type { ImpersonalSalaryJournalRecord } from "../../ExternalRostersApi";
import { FeeEntryType } from "../../ExternalRostersApi";
import type {
    PersonFeeCalculationParams
} from "./FeeCalculatorUtils";
import  { countFlatDutyBlockMinutes
} from "./FeeCalculatorUtils";
import  { dutifyRotationDays
} from "./FeeCalculatorUtils";
import  { getGroundActivityCategory
} from "./FeeCalculatorUtils";
import  { fetchMonthRotationDays
} from "./FeeCalculatorUtils";

import {
    getNias
} from "./FeeCalculatorUtils";


import { WW_ROTATION_PERIOD
} from "./FeeCalculatorUtils";
import {
    fixManually,
    groupSequences,
    isSoldDayOff,
    parseContract, parseRotationPeriod
} from "./FeeCalculatorUtils";
import { neverNull, typed } from "../../../utils/typing";
import type { DutiedRotationDay,RaidoActivityExtension,RaidoRotationDay, RotationDay } from "./RotationDaysGrouper";
import  { regroupToLt } from "./RotationDaysGrouper";
import  { getFilterByMonth } from "./RotationDaysGrouper";
import { type AbsoluteMonth,decrementMonth,getDatePart, getMonthEndDate, getMonthStartDate } from "../../../utils/dates";
import type { RosterItem } from "./Roster";
import type { NavInvoiceScreenParams } from "../../Api";
import type { MonthStrToCrewMap } from "../../../views/InvoiceRosterCalculation";

const OVERTIME_BLOCK_HOURS_THRESHOLD = 65;

/** probably should merge with consideredOffPayNiaStay()... */
function isOffRotationActivity(activity: RosterItem) {
    if (activity.ActivityType !== "Ground") {
        return false;
    }
    return activity.GNDACTCODETITLE === "CBO"
        || activity.GNDACTCODETITLE === "SVV"
        || activity.GNDACTCODETITLE.match(/^R\d+$/)
        || activity.GroundActivityInfo?.toUpperCase().includes("EMERGENCY LEAVE")
        || activity.GroundActivityInfo?.toUpperCase().includes("WAITING FOR LICENSE")
        || activity.GroundActivityInfo?.toUpperCase().includes("ROTATION")
        || activity.GroundActivityInfo?.toUpperCase().includes("UNPAID");
}

function endOffRotation(rotationDays: RotationDay[]) {
    const lastDay = rotationDays.slice(-1)[0];
    if (!lastDay) {
        return true;
    }
    const activities = rotationDays.flatMap(r => r.Activities);
    const lastActivity = activities.slice(-1)[0];
    const activityBeforeLast = activities.slice(-2)[0];
    if (!lastActivity) {
        return true;
    }
    if (lastDay.Activities.every(a => isOffRotationActivity(a)) ||
        lastActivity.ActivityType === "BLANK" &&
        activityBeforeLast &&
        isOffRotationActivity(activityBeforeLast)
    ) {
        return true;
    }
    return false;
}

/**
 * @param {number} rotationDays :
 *     [-1 -1 22 23 24 -1 -1 -1  1  2  3] -> [] [22 23 24] [1 2 3]
 *     [      22 23 24 -1 -1 -1 -1 -1 -1] ->    [22 23 24] []
 * The paid overtime hours are counted over the period of a rotation,
 * rotation often starts in the previous month and finishes in current month.
 * We consider that the first activity that does not sequentially follow the previous one marks the end of the rotation.
 * This function returns the last sequential group of days. If last day of the month is off-rotation, then
 * an empty array is returned, since last sequence was already covered in the previous payroll month.
 */
export function splitRotations(rotationDays: RaidoRotationDay[]): RaidoRotationDay[][] {
    const rotations: RaidoRotationDay[][] = [[]];
    let prevDay = null;
    for (const rotationDay of rotationDays) {
        const ending = [...prevDay ? [prevDay] : [], rotationDay];
        if (!endOffRotation(ending)) {
            rotations[rotations.length - 1].push(rotationDay);
        } else if (rotations[rotations.length - 1].length > 0) {
            rotations.push([]);
        } else if (!prevDay) {
            rotations.push([]);
        }
        prevDay = rotationDay;
    }
    return rotations;
}

export function endsPrematurely(rotation: RotationDay[]) {
    const lastActivity = rotation.slice(-1)[0]?.Activities.slice(-1)[0];
    if (endOffRotation(rotation) ||
        lastActivity && isOffRotationActivity(lastActivity)
    ) {
        return true;
    }
    return false;
}

/**
 * @param rotationDays - [1..72]
 * @param rotationPeriod = 30
 * @return [1..30], [31..60], [61..72]
 */
function chunk<T>(rotationDays: T[], rotationPeriod: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < rotationDays.length; i += rotationPeriod) {
        chunks.push(rotationDays.slice(i, i + rotationPeriod));
    }
    return chunks;
}

function getRotations(
    ltRotationDays: RaidoRotationDay[],
    precedingRemainder: RaidoRotationDay[],
    rotationPeriod: number
): RaidoRotationDay[][] {
    precedingRemainder = chunk(precedingRemainder, rotationPeriod).pop() ?? [];
    let rotations: RaidoRotationDay[][];
    if (precedingRemainder.length !== rotationPeriod) {
        // if it was longer than 30 days, then it was already handled in the previous month - see below
        rotations = splitRotations([...precedingRemainder, ...ltRotationDays]);
    } else {
        rotations = splitRotations(ltRotationDays);
    }
    return rotations.flatMap(rot => rot.length <= rotationPeriod ? [rot] : [
        rot.slice(0, rotationPeriod), rot.slice(rotationPeriod),
    ]);
}

function rotationsToHours(rotations: RaidoRotationDay[][]) {
    const allActivities = rotations
        .flatMap(r => r)
        .flatMap(d => d.Activities)
        .flatMap(a => a.ActivityType === "BLANK" ? [] : [a]);
    return rotations.filter(rotation => rotation.length > 0).map(rotation => {
        const startDate = rotation[0].Date;
        const endDate = rotation.slice(-1)[0].Date;
        const utcDateActivities = allActivities.filter(a => {
            const utcDate = getDatePart(a.FullRaidoActivity.Start);
            return utcDate >= startDate && utcDate <= endDate;
        });
        return {
            blockHours: countFlatDutyBlockMinutes(utcDateActivities) / 60,
            startDate: startDate,
            endDate: endDate,
        };
    });
}

export function getAllRotationBlockHours(
    ltRotationDays: RaidoRotationDay[],
    precedingRemainder: RaidoRotationDay[],
    rotationPeriod: number
) {
    const rotations = getRotations(ltRotationDays, precedingRemainder, rotationPeriod);
    return rotationsToHours(rotations);
}

export function getTerminatedRotationBlockHours(
    rotationDays: RaidoRotationDay[],
    precedingRemainder: RaidoRotationDay[],
    rotationPeriod: number
) {
    const rotations = getRotations(rotationDays, precedingRemainder, rotationPeriod);
    if ((rotations.slice(-1)[0] ?? []).length < rotationPeriod &&
        !endsPrematurely(rotationDays) ||
        rotations.slice(-1)[0]?.length === 0
    ) {
        // exclude unterminated ending - will be covered next month
        rotations.pop();
    }
    return rotationsToHours(rotations);
}

export type RotationPeriods = ReturnType<typeof getTerminatedRotationBlockHours>;

export async function getPrecedingUnterminatedRotationDays(
    currentMonth: NavInvoiceScreenParams,
    prefetchedNoc: MonthStrToCrewMap = new Map()
): Promise<RaidoRotationDay[]> {
    const MAX_BACKTRACK = 9;
    const precedingDays: RaidoRotationDay[] = [];
    let cursor: AbsoluteMonth = currentMonth;
    for (let i = 0; i < MAX_BACKTRACK; ++i) {
        cursor = decrementMonth(cursor);
        let allMonthDays = await fetchMonthRotationDays({
            ...currentMonth, ...cursor,
        }, prefetchedNoc);

        allMonthDays = !allMonthDays ? null :
            regroupToLt(cursor, allMonthDays)
                .filter(getFilterByMonth<{}>(cursor));

        if (!allMonthDays ||
            allMonthDays
                .flatMap(d => d.Activities)
                .filter(a => a.ActivityType !== "BLANK")
                .length === 0 ||
            endsPrematurely(allMonthDays)
        ) {
            return precedingDays;
        }
        const rotations = splitRotations(allMonthDays);
        if (rotations.length > 1) {
            precedingDays.unshift(...splitRotations(allMonthDays).pop() ?? neverNull());
            return precedingDays;
        } else {
            precedingDays.unshift(...allMonthDays);
        }
    }
    throw new Error(
        "Failed to find the start of rotation for " + currentMonth.employeeCode +
        " after looking " + MAX_BACKTRACK + " months back"
    );
}

type DailyFeeKind = "100" | "100_SICKNESS" | "135" | "150" | "200" | "300";

function getDailyFeeRecords(
    aircraft: string,
    dailyFeeFinal: `${number}`,
    rotationDays: DutiedRotationDay[]
): ImpersonalSalaryJournalRecord[] {
    const kindToDutyDays = new Map<DailyFeeKind, RotationDay[]>();
    let dutyDayIndex = 0;
    for (const rotationDay of rotationDays) {
        const cargoSeason2024Applies =
            aircraft.includes("747") &&
            rotationDay.Date >= "2024-10-01" &&
            rotationDay.Date <= "2024-12-22";
        if (rotationDay.DutyKind === "OFF_DUTY") {
            continue;
        }
        ++dutyDayIndex;
        let kind: DailyFeeKind;
        if (rotationDay.Date === "2025-01-01" ||
            rotationDay.Date === "2024-12-31" ||
            rotationDay.Date === "2024-12-25" ||
            rotationDay.Date === "2024-12-24"
        ) {
            kind = "300";
        } else if (rotationDay.Date === "2024-12-30"
                || rotationDay.Date === "2024-12-29"
                || rotationDay.Date === "2024-12-28"
                || rotationDay.Date === "2024-12-27"
                || rotationDay.Date === "2024-12-26"
                || rotationDay.Date === "2024-12-23"
        ) {
            kind = "200";
        } else if (dutyDayIndex >= 19
                && cargoSeason2024Applies
        ) {
            kind = "200";
        } else if (dutyDayIndex >= 16
                && cargoSeason2024Applies
        ) {
            kind = "150";
        } else if (isSoldDayOff(rotationDay)) {
            kind = "135";
            --dutyDayIndex;
        } else {
            if (rotationDay.Activities.some(a => getGroundActivityCategory(a) === "ILLNESS")) {
                kind = "100_SICKNESS";
            } else {
                kind = "100";
            }
        }
        let group = kindToDutyDays.get(kind);
        if (!group) {
            group = [];
            kindToDutyDays.set(kind, group);
        }
        group.push(rotationDay);
    }
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    for (const [kind, dutyDays] of kindToDutyDays) {
        const multiplier = kind === "100_SICKNESS" ? 100 : +kind;
        journalRecords.push(...groupSequences(dutyDays ?? neverNull())
            .map(sequence => typed<ImpersonalSalaryJournalRecord>({
                service_start_time: sequence[0].Date,
                service_end_time: sequence.slice(-1)[0].Date,
                units: `${sequence.length}`,
                payment_per_unit: (+dailyFeeFinal * +multiplier / 100).toFixed(2),
                ...kind === "135" ? {
                    fee_entry_type: FeeEntryType(108),
                    description: "Extra days on duty FD",
                } : kind === "100_SICKNESS" ? {
                    fee_entry_type: FeeEntryType(281),
                    description: "Sickness 100%",
                } : kind === "100" ? {
                    fee_entry_type: FeeEntryType(200),
                    description: "Daily Fee",
                } : {
                    fee_entry_type: FeeEntryType(200),
                    description: "Daily Fee x" + (+multiplier / 100).toFixed(2).replace(/\.?0+$/, ""),
                },
            })));
    }
    return journalRecords;
}

export function calculateFeesForFlightDeckPerson(
    person: PersonFeeCalculationParams & {
        aircraft: string,
        precedingUnterminatedRotationDays: RaidoRotationDay[],
    },
    officerFees: OfficerFlightDeckFees | null
): ImpersonalSalaryJournalRecord[] {
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    const nias = getNias(person);

    let dailyFeeRaw: string | null = officerFees?.dailyFee ?? null;
    let dailyFee: `${number}` | undefined | null;
    if (dailyFeeRaw && String(+dailyFeeRaw) === String(dailyFeeRaw)) {
        dailyFee = dailyFeeRaw as `${number}`;
    }
    let rotationPeriod = officerFees?.rotationType ? parseRotationPeriod(officerFees.rotationType) : null;
    if (!person.latestContractData) {
        if (!dailyFee) {
            journalRecords.push(...fixManually(person, "Contract Missing"));
            dailyFee = "0";
        }
    } else if (person.latestContractData.document_kind === "TRAINING_AGREEMENT") {
        journalRecords.push(...fixManually(person, "Training Agreement"));
        if (!dailyFee) {
            dailyFee = "0";
        }
    } else {
        const parsed = parseContract(person.latestContractData);
        dailyFee = dailyFee ?? parsed.dailyFee;
        dailyFeeRaw = parsed.dailyFeeRaw ?? dailyFeeRaw;
        rotationPeriod = parsed.rotationPeriod ?? rotationPeriod;
        if (!dailyFee) {
            journalRecords.push(...fixManually(person, "Contract Fee Format Unsupported " + dailyFeeRaw));
            dailyFee = "0";
        }
    }
    const dailyFeeFinal = dailyFee;

    const dutylessRotationDaysUtc = person.rotationDays;
    const dutylessRotationDaysLt = regroupToLt(person, dutylessRotationDaysUtc);
    const ltRotationDays = dutifyRotationDays(nias, dutylessRotationDaysLt)
        .filter(getFilterByMonth<RaidoActivityExtension>(person));
    journalRecords.push(...getDailyFeeRecords(
        person.aircraft, dailyFeeFinal, ltRotationDays
    ));

    const isCruiseRelief = person.navEntry.JobTitle === "CRUISE RELIEF FIRST OFFICER";
    const perDiem = isCruiseRelief ? "50" : "85";
    const perDiemDays = ltRotationDays.filter(ri => {
        if (ri.Activities.some(a => a.GNDACTCODETITLE === "PXP")) {
            return false;
        }
        return ri.DutyKind === "TRAVEL"
            || ri.DutyKind === "DAY_SHIFT_TRAVEL_END"
            || ri.DutyKind === "DAY_SHIFT_TRAVEL_START"
            || ri.DutyKind === "NIA_GROUND_DUTY";
    });

    journalRecords.push(...groupSequences(perDiemDays)
        .map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(500),
            description: "Per diem",
            payment_per_unit: perDiem,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })));
    if (!isCruiseRelief) {
        const overtimes = getTerminatedRotationBlockHours(
            ltRotationDays.filter(getFilterByMonth<RaidoActivityExtension>(person)),
            person.precedingUnterminatedRotationDays,
            WW_ROTATION_PERIOD
        );
        for (const overtime of overtimes) {
            const overtimeHours = overtime.blockHours - OVERTIME_BLOCK_HOURS_THRESHOLD;
            if (overtimeHours > 0) {
                const factor = person.year < 2024 ? 0.6 : 0.39;
                journalRecords.push({
                    service_start_time: overtime.startDate,
                    service_end_time: overtime.endDate,
                    units: overtimeHours.toFixed(2),
                    payment_per_unit: (factor * +dailyFee).toFixed(2),
                    fee_entry_type: FeeEntryType(300),
                    description: "Over time FD",
                });
            }
        }
    }
    if (officerFees) {
        if (officerFees.instructorFee && +officerFees.instructorFee > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.instructorFee,
                fee_entry_type: FeeEntryType(670),
                description: "Instructor fee 010811",
            });
        }
        if (officerFees.safetyOfficerFee && +officerFees.safetyOfficerFee > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.safetyOfficerFee,
                fee_entry_type: FeeEntryType(209),
                description: "Safety Officer Fee",
            });
        }
        if (officerFees.telecommunicationsAllowance && +officerFees.telecommunicationsAllowance > 0) {
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: "1",
                payment_per_unit: officerFees.telecommunicationsAllowance,
                fee_entry_type: FeeEntryType(4005),
                description: "Phone and Internet allowance",
            });
        }
    }
    return journalRecords;
}
