import * as React from "react";
import type { ImpersonalBeneficiaryBankAccount, NavCompanyData, Payslip } from "../types/NavisionPayslip";
import type { NavInvoiceScreenParams } from "../features/Api";
import api from "../features/Api";
import type {
    ConfirmingParty,
    INavInvoiceConfirmation,
    INavInvoiceConfirmationBase, StoredFinalizationData, StoredFinalizationsMapping,
} from "../types/Api/Invoice";
import { getMatchingPayroll, transferStatementUnlockTime } from "../features/payrollUtils";
import CustomFields from "../utils/CompanySpecificFields";
import {
    decrementMonth,
    formatLocalDate,
    formatUtcDate,getMonth,
    getMonthStartDate,getMonthStr,getNthWorkingDayInLatvia,
    incrementMonth
} from "../utils/dates";
import type { FullPayslip } from "../types/NavisionPayslip";
import InvoiceRosterView, { getCompanyRenderer } from "../components/InvoiceRosterView";
import { HttpResponseError } from "../features/httpUtils";
import FeeStatementInvoiceConfirmationMessaging
    from "../components/fee-statement-invoice-confirmation-components/FeeStatementInvoiceConfirmationMessaging";
import ContractorMonthlyPayslipInvoice from "../components/ContractorMonthlyPayslipInvoice";
import { deadlinePassed } from "../utils/feeStatementInvoiceUtils";
import type { Dispatch, Store } from "redux";
import type { Provider } from "react";
import FeeStatementInvoiceConfirmationSheetDataView
    from "../components/fee-statement-invoice-confirmation-components/FeeStatementInvoiceConfirmationSheetDataView";
import { isAirAtlanta, isCabinCrewDepartment } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import type { ClaimBaseData } from "../types/Claim";
import { getPartTitle, NAV_INVOICE_STATUS_DB_LABELS } from "../utils/invoiceListingUtils";
import type { InvoiceConfirmationData } from "../types/feeStatementTypes";
import { fromEntries,neverNull } from "../utils/typing";
import { getPayrollFinalizationsMapping } from "../features/StaticData";

type Props = NavInvoiceScreenParams & {
    locatesSelf: boolean,
};

type State = {
    fullPayslip: FullPayslip | null,
    fullPayslipExpectedErrorStatus: 404 | 425 | null,
    companyData: NavCompanyData | null,
    partyConfirmations: null | Partial<Record<ConfirmingParty, INavInvoiceConfirmationBase | INavInvoiceConfirmation>>,
    payrollFinalizationsMapping: null | StoredFinalizationsMapping<StoredFinalizationData>,
    approvedSoeEntries: ClaimBaseData[],
    loadingStatus: "STARTED" | "HANGING" | "DONE",
    submittingEmail: boolean,
};

interface Window {
    /** @see https://ekoopmans.github.io/html2pdf.js/#options */
    html2pdf: () => {
        from: (element: HTMLElement) => {
            set: (params: {
                html2canvas?: {
                    /** set to at least 2, otherwise text will be blurry */
                    scale?: number,
                },
            }) => {
                /** shows PDF save popup to user */
                save: () => void,
            },
        },
    },
}

type PropsWithJunk = Props & { store: Store, provider: Provider<unknown>, dispatch: Dispatch };

function cleanupProps(props: PropsWithJunk) {
    const { store, provider, dispatch, ...locator } = props;
    return locator;
}

function arePaymentsOnStop(account: ImpersonalBeneficiaryBankAccount) {
    return account.LineSheetNumber.toUpperCase().includes("STOP");
}

function nonSaudicBankWarningApplies(company: string, account: ImpersonalBeneficiaryBankAccount, department: string | null) {
    return !arePaymentsOnStop(account)
        && company === "HEL - AAIUSD"
        && department
        && isCabinCrewDepartment(department)
        && account.BankCountry !== "SA";
}

function formatDeadline(deadline: Date) {
    return formatLocalDate(deadline) + " " +
        String(deadline.getHours()).padStart(2, "0") + ":" +
        String(deadline.getMinutes()).padStart(2, "0");
}

export default class FeeStatementInvoiceConfirmation extends React.Component<PropsWithJunk, State> {
    constructor(props: PropsWithJunk) {
        super(props);
        require("../components/ContractorMonthlyPayslipInvoice.css");
        require("./FeeStatementInvoiceConfirmation.css");
        const locator = cleanupProps(props);
        this.state = {
            fullPayslip: null,
            fullPayslipExpectedErrorStatus: null,
            companyData: null,
            partyConfirmations: null,
            payrollFinalizationsMapping: null,
            approvedSoeEntries: [],
            loadingStatus: "STARTED",
            submittingEmail: false,
        };
        this.fetchData(locator);
    }

    async fetchData(locator: NavInvoiceScreenParams) {
        const whenConfirmations = api.Invoice.GetNavInvoiceConfirmations(locator);
        const whenCompanyData = api.Company.GetNavCompanyData(locator);
        const whenFullPayslip = api.Invoice.GetFeeStatementInvoice(locator)
            .catch(error => {
                if (error instanceof HttpResponseError && (
                    error.response.status === 404 ||
                    error.response.status === 425
                )) {
                    this.setState({ fullPayslipExpectedErrorStatus: error.response.status });
                    // expected: user may traverse past/upcoming month rosters that were not invoiced
                    return null;
                } else {
                    throw error;
                }
            });

        whenCompanyData.then(companyData => this.setState({ companyData }));
        whenFullPayslip.then(data => this.setState({ fullPayslip: data }))
            .finally(() => this.setState({ loadingStatus: "DONE" }));
        setTimeout(() => this.setState(prev => ({
            loadingStatus: prev.loadingStatus === "STARTED" ? "HANGING" : prev.loadingStatus,
        })), 10000);

        getPayrollFinalizationsMapping()
            .then(payrollFinalizationsMapping => this.setState({ payrollFinalizationsMapping }));
        api.Claim.GetClaimsForNavInvoiceConfirmation(locator)
            .then(approvedSoeEntries => this.setState({ approvedSoeEntries }));
        whenConfirmations.then((confirmations) => {
            this.setState({
                partyConfirmations: fromEntries(confirmations.map(conf => [conf.confirming_party, conf])),
            });
        });

        const companyData = await whenCompanyData;
        const fullPayslip = await whenFullPayslip;
        await whenConfirmations;
    }

    private get locator() {
        return cleanupProps(this.props);
    }

    private getConfirmationTotalSuffix(confirmation: INavInvoiceConfirmationBase | INavInvoiceConfirmation | null) {
        if (confirmation && (
            confirmation.status === "CONFIRMED" ||
            confirmation.status === "CONFIRMED_BY_CLIENT" ||
            confirmation.status === "DISCREPANCY_REPORTED" ||
            confirmation.status === "DISCREPANCY_REPORTED_BY_CLIENT"
        )) {
            return " for " + this.state.companyData?.glSetup["LCY Code"] + " " + confirmation.confirmation_total?.toFixed(2);
        } else {
            return "";
        }
    }

    private setLastConfirmation(confirmation: INavInvoiceConfirmationBase) {
        this.setState({
            partyConfirmations: {
                ...this.state.partyConfirmations,
                [confirmation.confirming_party]: {
                    ...(this.state.partyConfirmations ?? neverNull())[confirmation.confirming_party] ?? {},
                    ...confirmation,
                },
            },
        });
    }

    private getStatusLabel(confirmingParty: ConfirmingParty, confirmation: INavInvoiceConfirmationBase | INavInvoiceConfirmation | null) {
        if (!this.state.partyConfirmations ||
            this.state.loadingStatus !== "DONE" ||
            !this.state.payrollFinalizationsMapping
        ) {
            return "Loading...";
        }
        const status = confirmation?.status;
        if (!status) {
            if (confirmingParty === "CONTRACTOR") {
                if (!this.currentPayrollFinalization) {
                    return "Not Sent";
                }
                if (deadlinePassed(this.deadline)) {
                    return "Auto-Confirmed due to Timeout";
                }
            }
            return "Awaiting Confirmation";
        }
        return {
            ...NAV_INVOICE_STATUS_DB_LABELS,
            "NOT_FINALIZED": "Not Sent",
            "CHANGED_AFTER_DISCREPANCY_REPORT": "Being Resolved",
            "SUBMITTING": "Submitting...",
        }[status] ?? status;
    }

    private get customFields() {
        return !this.state.fullPayslip || !this.state.companyData ? undefined :
            new CustomFields(this.state.companyData, this.state.fullPayslip.payslip);
    }

    private get currentPayrollFinalization(): null | StoredFinalizationData {
        if (!this.customFields || !this.state.payrollFinalizationsMapping) {
            return null;
        }
        const department = +(this.customFields.departmentCode ?? neverNull());
        return getMatchingPayroll(this.state.payrollFinalizationsMapping, {
            ...this.locator,
            department: department,
        });
    }

    private get deadline() {
        if (!this.currentPayrollFinalization) {
            return null;
        } else {
            return new Date(this.currentPayrollFinalization.ConfirmationDeadline);
        }
    }

    get contractFileLink() {
        if (!this.locator.locatesSelf && !window.EMPLOYEE_INFO?.IsAdmin) {
            return undefined;
        } else {
            return "/api/Contract/GetLatestContractFile?" + new URLSearchParams({
                year: this.locator.year.toString(),
                month: this.locator.month.toString(),
                employeeCode: this.locator.employeeCode,
                company: this.locator.company,
                ...!this.locator.accessToken ? {} : {
                    accessToken: this.locator.accessToken,
                },
            }) + "#page=14";
        }
    }

    renderMonthsNavigationPanel() {
        const upcomingMonth = incrementMonth(getMonth(new Date()));
        const yearMonths = [
            decrementMonth(this.locator),
            incrementMonth(this.locator),
        ].filter(yearMonth => {
            const datePrefix = getMonthStr(yearMonth);
            return datePrefix >= "2017-01"
                && datePrefix <= getMonthStr(upcomingMonth);
        });
        return <div className="months-navigation-panel">
            {yearMonths.map(yearMonth => {
                const url = new URL(window.location.href);
                url.searchParams.delete("accessToken");
                url.searchParams.set("year", yearMonth.year.toString());
                url.searchParams.set("month", yearMonth.month.toString());
                const content = yearMonth.year + "-" + String(yearMonth.month).padStart(2, "0");
                return <a key={url.href} href={url.href}>{content}</a>;
            })}
        </div>;
    }

    private getBeneficiaryEmail(record: Payslip) {
        return record.VendorName && !record.VendorIsUmbrella ? record.VendorEmail : record["E-Mail"];
    }

    private async sendCrewConfirmationLink() {
        const confirmMessage = "Send an email to contractor with a link to this page?";
        if (!confirm(confirmMessage)) {
            return;
        }
        this.setState({ submittingEmail: true });
        try {
            await api.Invoice.SendIndividualNavConfirmationLink(this.locator);
        } finally {
            this.setState({ submittingEmail: false });
        }
    }

    private async sendTransferStatement() {
        const confirmMessage = "Are you sure that this invoice is ready to be paid?";
        if (!confirm(confirmMessage)) {
            return;
        }
        this.setState({ submittingEmail: true });
        try {
            await api.Invoice.SendIndividualTransferStatement(this.locator);
        } finally {
            this.setState({ submittingEmail: false });
        }
    }

    private get transferStatementUnlockTime(): Date {
        return transferStatementUnlockTime(this.locator);
    }

    private get readyForTransferStatement() {
        return Date.now() >= this.transferStatementUnlockTime.getTime();
    }

    private get confirmationData(): InvoiceConfirmationData | null {
        return !this.state.fullPayslip || !this.state.companyData ? null : {
            locator: this.locator,
            partyConfirmations: this.state.partyConfirmations,
            fullPayslip: this.state.fullPayslip,
            companyData: this.state.companyData,
        };
    }

    private getBeneficiaryBankCountryWarning(account: ImpersonalBeneficiaryBankAccount) {
        if (arePaymentsOnStop(account)) {
            return "the payment is put on hold until bank details or any other fee related matter is cleared";
        }
        if (nonSaudicBankWarningApplies(this.props.company, account, this.customFields?.departmentCode ?? null)) {
            return "not KSA, transfer of funds may get delayed";
        }
        return null;
    }

    private get approvedSoeCutoutWorkingDay() {
        return isAirAtlanta(this.locator.company) ? 4 : 5;
    }

    private get approvedSoeCutoutDate() {
        const transferMonth = incrementMonth(this.locator);
        const monthStart = new Date(getMonthStartDate(transferMonth));
        return getNthWorkingDayInLatvia(monthStart, this.approvedSoeCutoutWorkingDay);
    }

    private isThisMonthSoe(soe: ClaimBaseData) {
        const approvedOn = soe.SubmittedToNavOn ?? soe.ModifiedOn;
        return approvedOn && approvedOn.slice(0, 10) <= this.approvedSoeCutoutDate.toISOString().slice(0, 10);
    }

    private get approvedSoeEntriesThisMonth() {
        return this.state.approvedSoeEntries
            .filter(soe => this.isThisMonthSoe(soe));
    }

    private get approvedSoeEntriesNextMonth() {
        return this.state.approvedSoeEntries
            .filter(soe => !this.isThisMonthSoe(soe));
    }

    private renderSoeSectionTable(soeEntries: ClaimBaseData[]) {
        return <table style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th></th>
                    <th>Category</th>
                    <th style={{ textAlign: "right" }}>Spent</th>
                    <th style={{ textAlign: "right" }}>Approved</th>
                    <th style={{ textAlign: "right" }}>Currency</th>
                    <th style={{ textAlign: "right" }}>Amount</th>
                </tr>
            </thead>
            <tbody>
                {soeEntries.map(soe => <tr key={soe.Id}>
                    <td>
                        <a href={this.props.locatesSelf ? "/Home/UpdateClaim?claimId=" + soe.Id : "/Home/ApproveClaim?claimId=" + soe.Id} target="_blank">
                            <img style={{ width: "24px" }} className={"halfOpacity"} src="/img/view.png"/>
                        </a>
                    </td>
                    <td>{soe.SubCategory || soe.Category}</td>
                    <td style={{ textAlign: "right" }}>{formatUtcDate(new Date(soe.Date))}</td>
                    <td style={{ textAlign: "right" }}>{soe.ModifiedOn && formatLocalDate(new Date(soe.ModifiedOn))}</td>
                    <td style={{ textAlign: "right" }}>{String(soe.FromCurrency)}</td>
                    <td style={{ textAlign: "right" }}>{Number(soe.FromAmount).toFixed(2)}</td>
                </tr>)}
            </tbody>
        </table>;
    }

    private get renderSoeSection() {
        return <>
            <div style={{ marginBottom: "18px", color: "rgb(32, 94, 120)", maxWidth: "620px" }}>
                * {formatUtcDate(this.approvedSoeCutoutDate)}: As per the procedure the cut off date for expenses (SoE) is the {this.approvedSoeCutoutWorkingDay}th business day of the month. Expenses approved after that date will be moved to the next month payroll.
            </div>
        </>;
    }

    private renderInvoiceStatusRow(confirmingParty: ConfirmingParty) {
        const confirmation = this.state.partyConfirmations?.[confirmingParty] ?? null;
        const statusLabel = this.getStatusLabel(confirmingParty, confirmation);
        return <div data-confirmation-status={confirmation?.status ?? ""} className="colored-nav-invoice-status-holder">
            <span>{getPartTitle(confirmingParty)} Status: </span>
            <span className="statusField">{statusLabel}</span>
            <span>{this.getConfirmationTotalSuffix(confirmation)}</span>
        </div>;
    }

    render() {
        const canShowRoster = !!getCompanyRenderer(this.props.company);
        const notInvoicedYet = this.state.loadingStatus === "DONE" && !this.state.fullPayslip;
        const hidePreliminarySalaryJournal = this.locator.locatesSelf
            || this.props.company === "F2R - ETFEUR" // for F2R - ETFEUR we always show automatic calculation to the contractor since final invoice is summarized and lacks the details
            || notInvoicedYet && isAirAtlanta(this.props.company) // for Air Atlanta automatic calculation is usually correct, so it's safe to show
            ;
        return (
            <div className={"fee-statement-invoice-confirmation-screen" + (canShowRoster ? " can-show-roster" : "")}>
                {this.state.loadingStatus === "HANGING" ? <div className="nav-request-hangs-warning">
                    It seems to take too long to retrieve the invoice data. Possibly a recalculation is taking place right now. Please visit this page again in 10 or so minutes...
                </div> : <div className="feeStatementInvoiceScreen">
                    {this.renderMonthsNavigationPanel()}
                    <h2>{this.state.fullPayslipExpectedErrorStatus === 404 ? "There is no" : "Here is " + (this.locator.locatesSelf ? "your" : "contractor's")} invoice
                        for {this.locator.year}-{this.locator.month}</h2>
                    <div className="downloadButtonRow">
                        <div>
                            {this.renderInvoiceStatusRow("CONTRACTOR")}
                            {this.renderInvoiceStatusRow("CLIENT")}
                        </div>
                        {this.deadline && (this.state.partyConfirmations?.CONTRACTOR ?? "SENT_TO_CONTRACTOR_FOR_CONFIRMATION") === "SENT_TO_CONTRACTOR_FOR_CONFIRMATION" && (
                            !deadlinePassed(this.deadline)
                                ? <span> Submit before: {formatDeadline(this.deadline)}</span>
                                : <span> On {formatDeadline(this.deadline)}</span>
                        )}
                        {!this.state.fullPayslip ? undefined : <button onClick={() => {
                            const extendedWindow = window as unknown as Window;
                            if (!extendedWindow.html2pdf) {
                                const message = "PDF still loading. Please, wait few seconds and try again";
                                if (window.toastr) {
                                    window.toastr.warning(message);
                                } else {
                                    alert(message);
                                }
                                return;
                            }
                            const element = document.getElementById("print-invoice-content") as HTMLElement;
                            extendedWindow.html2pdf().from(element).set({
                                html2canvas: { scale: 2 },
                            }).save();
                        }}>Download/Print ⎙</button>}
                    </div>
                    {this.confirmationData && <>
                        <div className="invoiceBorder" key="INVOICE_BORDER">
                            <ContractorMonthlyPayslipInvoice
                                id="print-invoice-content"
                                navCompanyData={this.confirmationData.companyData}
                                fullPayslip={this.confirmationData.fullPayslip}
                            />
                        </div>
                        {this.renderSoeSection}
                        {(window.EMPLOYEE_INFO?.IsAdmin || this.props.locatesSelf) && this.state.fullPayslip && <div className="beneficiary-bank-accounts-section">
                            {this.state.fullPayslip.beneficiaryBankAccounts.length === 0 && <div className="has-warning">
                                Beneficiary Bank Details Missing. Please, make sure they are entered at the <a target="_blank" href="https://hiportal.eu/Secure/Candidates/Membership/BankDetails.aspx">HiPortal</a>
                            </div>}
                            {this.state.fullPayslip.beneficiaryBankAccounts.map(bba => <div key={bba.AccountIndex}>
                                Beneficiary Bank Country: {this.getBeneficiaryBankCountryWarning(bba)
                                    ? <span className="beneficiary-bank-country-code">{bba.BankCountry} (<span className="has-warning">{this.getBeneficiaryBankCountryWarning(bba)}</span>).<br/>You can update your bank details at <a target="_blank" href="https://hiportal.eu/Secure/Candidates/Membership/BankDetails.aspx">HiPortal</a></span>
                                    : <span className="beneficiary-bank-country-code">{bba.BankCountry}</span>}
                            </div>)}
                        </div>}
                        {window.EMPLOYEE_INFO?.IsAdmin && <div className="contractor-email-section">
                            <span>Confirmation E-Mail: {this.confirmationData.fullPayslip.payslip["E-Mail"]}</span>
                            <button
                                onClick={() => this.sendCrewConfirmationLink()}
                                disabled={this.state.submittingEmail}
                                className={this.state.submittingEmail ? "status-loading-animated-ellipsis" : ""}
                            >Re-Send Confirmation Link</button>
                        </div>}
                        {window.EMPLOYEE_INFO?.IsAdmin && <div className="contractor-email-section">
                            <span>Final Invoice E-Mail: {this.getBeneficiaryEmail(this.confirmationData.fullPayslip.payslip)}</span>
                            <button
                                onClick={() => this.sendTransferStatement()}
                                disabled={this.state.submittingEmail || !this.readyForTransferStatement}
                                className={this.state.submittingEmail ? "status-loading-animated-ellipsis" : ""}
                                title={"Unlocked on " + this.transferStatementUnlockTime.toISOString().slice(0, 10)}
                            >
                                {!this.readyForTransferStatement
                                    ? "Final Invoice on " + this.transferStatementUnlockTime.toISOString().slice(0, 10)
                                    : this.state.submittingEmail ? "Submitting" : "Send Final Invoice"}
                            </button>
                        </div>}
                        <FeeStatementInvoiceConfirmationMessaging
                            confirmationData={this.confirmationData}
                            setLastConfirmationStatus={this.setLastConfirmation.bind(this)}
                            deadline={this.deadline}
                        />
                    </>}
                </div>}
                <div className={"roster-vs-manual-split-view " + (hidePreliminarySalaryJournal ? " hide-preliminary-salary-journal" : "")} data-company={this.props.company}>
                    <FeeStatementInvoiceConfirmationSheetDataView locator={this.locator}/>
                    <InvoiceRosterView
                        locator={this.locator}
                        contractFileLink={this.contractFileLink}
                    />
                </div>
            </div>
        );
    }
}
