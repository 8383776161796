import type {
    PersonFeeCalculationParams
} from "./FeeCalculatorUtils";
import  { dutifyRotationDays
} from "./FeeCalculatorUtils";
import {
    countDutyBlockMinutes
} from "./FeeCalculatorUtils";
import {
    assertPositioning,
    getPositioningBlockMinutes
} from "./FeeCalculatorUtils";
import {
    getNias
} from "./FeeCalculatorUtils";
import {
    fixManually, getGroundActivityCategory, groupSequences, isSoldDayOff,
    parseContract
} from "./FeeCalculatorUtils";
import type { ImpersonalSalaryJournalRecord } from "../../ExternalRostersApi";
import  { FeeEntryType } from "../../ExternalRostersApi";
import type { DutiedRotationDay, RotationDay } from "./RotationDaysGrouper";
import  { getFilterByMonth, type RaidoActivityExtension,regroupToLt } from "./RotationDaysGrouper";
import { typed } from "../../../utils/typing";
import { getMonthEndDate, getMonthStartDate } from "../../../utils/dates";


function getPositioningFlights(rotationDays: RotationDay[]) {
    const allActivities = rotationDays.flatMap(rd => rd.Activities);
    const positioningFlights = [];
    for (const activity of allActivities) {
        const asPositioning = assertPositioning(activity);
        if (!asPositioning) {
            continue;
        }
        positioningFlights.push(asPositioning);
    }
    return positioningFlights;
}

export function getPositioningTimeSummary(nias: Set<string>, rotationDays: RotationDay[]) {
    let deadheadMinutes = 0;
    let positioningMinutes = 0;
    for (const activity of getPositioningFlights(rotationDays)) {
        const minutes = getPositioningBlockMinutes(activity);
        const isDeadhead = activity.ActivityType === "Deadhead" ||
            activity.FullRotationActivity?.DutyDesignators?.includes("[D]");
        if (isDeadhead) {
            deadheadMinutes += minutes;
        } else {
            positioningMinutes += minutes;
        }
    }
    return {
        deadheadMinutes,
        positioningMinutes,
    };
}

export function calculateFeesForCabinCrewPerson(
    person: PersonFeeCalculationParams
): ImpersonalSalaryJournalRecord[] {
    const journalRecords: ImpersonalSalaryJournalRecord[] = [];
    const nias = getNias(person);

    let dailyFee: `${number}`, dailyFeeRaw;
    if (!person.latestContractData) {
        journalRecords.push(...fixManually(person, "Contract Missing"));
        dailyFee = "0";
    } else {
        const parsed = parseContract(person.latestContractData);
        ({ dailyFeeRaw } = parsed);
        if (!parsed.dailyFee) {
            journalRecords.push(...fixManually(person, "Contract Fee Format Unsupported " + dailyFeeRaw));
            dailyFee = "0";
        } else {
            dailyFee = parsed.dailyFee;
        }
    }

    const dutylessRotationDaysUtc = person.rotationDays;
    const dutylessRotationDaysLt = regroupToLt(person, dutylessRotationDaysUtc);
    const rotationDays = dutifyRotationDays(nias, dutylessRotationDaysLt)
        .filter(getFilterByMonth<RaidoActivityExtension>(person));
    let anyDutyDays = rotationDays.filter(ri => ri.DutyKind !== "OFF_DUTY");
    const soldOffDays = rotationDays.filter(isSoldDayOff);

    journalRecords.push(...groupSequences(soldOffDays)
        .map(sequence => typed<ImpersonalSalaryJournalRecord>({
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
            payment_per_unit: dailyFee,
            fee_entry_type: FeeEntryType(320),
            description: "Sold day off",
        })));

    let ccmNiaTrainingDays: RotationDay[] = [];
    if (person.navEntry.JobTitle === "CABIN CREW MEMBER") {
        const isNiaTrainingDay = (rd: DutiedRotationDay) => rd.DutyKind === "NIA_GROUND_DUTY" &&
            // probably better would be to create a separate duty kind: NIA_TRAINING, but too lazy
            rd.Activities.some(a => getGroundActivityCategory(a) === "TRAINING");
        ccmNiaTrainingDays = anyDutyDays.filter(d => isNiaTrainingDay(d));
        anyDutyDays = anyDutyDays.filter(d => !isNiaTrainingDay(d));
    }
    journalRecords.push(
        ...groupSequences(ccmNiaTrainingDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(580),
            description: "NIA Training Daily Fee",
            payment_per_unit: "0", // paid only upon arrival to JED base which may be in next month or never
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        })),
    );
    const dutyBlockMinutes = countDutyBlockMinutes(rotationDays);
    const OVERTIME_THRESHOLD_MINUTES = 70 * 60;
    if (dutyBlockMinutes > OVERTIME_THRESHOLD_MINUTES) {
        const overtimeUnits = (dutyBlockMinutes - OVERTIME_THRESHOLD_MINUTES) / 60;
        journalRecords.push({
            service_start_time: getMonthStartDate(person),
            service_end_time: getMonthEndDate(person),
            units: overtimeUnits.toFixed(2),
            payment_per_unit: "10",
            fee_entry_type: FeeEntryType(306),
            description: "Overtime Cabin",
        });
    }
    const isHajjPeriod = person.month === 5 || person.month === 6 || person.month === 7;
    if (isHajjPeriod) {
        const remainingThreshold = Math.max(0, OVERTIME_THRESHOLD_MINUTES - dutyBlockMinutes);
        const positioningMinutes = getPositioningTimeSummary(nias, rotationDays).positioningMinutes;
        if (positioningMinutes > remainingThreshold) {
            const positioningOvertimeUnits = (positioningMinutes - remainingThreshold) / 60;
            journalRecords.push({
                service_start_time: getMonthStartDate(person),
                service_end_time: getMonthEndDate(person),
                units: positioningOvertimeUnits.toFixed(2),
                payment_per_unit: "5",
                fee_entry_type: FeeEntryType(310),
                description: "Positioning Overtime/Hajj",
            });
        }
    }

    journalRecords.push(
        ...groupSequences(anyDutyDays).map(sequence => typed<ImpersonalSalaryJournalRecord>({
            fee_entry_type: FeeEntryType(200),
            description: "Daily Fee",
            payment_per_unit: dailyFee,
            service_start_time: sequence[0].Date,
            service_end_time: sequence.slice(-1)[0].Date,
            units: `${sequence.length}`,
        }))
    );

    return journalRecords;
}