import type {
    ImpersonalSalaryJournalRecord, NavContractorBase,
    PersonMonthRosterContent, RdbContractor
} from "../../ExternalRostersApi";
import externalRostersApi from "../../ExternalRostersApi";
import  { FeeEntryType
} from "../../ExternalRostersApi";

import type { AbsoluteMonth } from "../../../utils/dates";
import { incrementDay } from "../../../utils/dates";
import { getMonthStr } from "../../../utils/dates";
import { getMonthEndDate, getMonthStartDate } from "../../../utils/dates";
import type  { SignedDocumentRowBase } from "../../../types/Api/Contract";
import aaiActivityTypes from "./aaiActivityTypes";
import type { DutyKind,DutyKindExtension,RaidoRotationDay, RotationDay } from "./RotationDaysGrouper";
import  { getLocalEndDate } from "./RotationDaysGrouper";
import { getRotationDaysFromFullUnfiltered } from "./RotationDaysGrouper";
import { raidoToRm } from "./RotationDaysGrouper";
import type { BlankActivity, RosterItem,RotationActivity } from "./Roster";
import type { CompanyName,NavInvoiceScreenParams } from "../../Api";
import { neverNull,toCrewCodeUc,toUpperCase } from "../../../utils/typing";
import type { RosterActivity } from "@mhc/utils/types/integrations/raido/api";
import aaiRaidoActivityTypes from "./aaiRaidoActivityTypes";
import { HttpResponseError } from "../../httpUtils";
import type { MonthStrToCrewMap } from "../../../views/InvoiceRosterCalculation";
import type { IataAirport } from "@mhc/utils/types/utility";

/** there are also some 21 and 24 contracts, we should take this value from Inna's table eventually */
export const WW_ROTATION_PERIOD = 30;

/**
 * there are cases when crew is stationed in an airport that matches his NIA, but he
 * stays in the hotel as it's not an unpaid off day, just a stop between flights
 */
function isDutyStop(activity: LastRealActivity, days: RotationDay[]) {
    const activities = days.flatMap(d => d.Activities);
    const baseIndex = activities.findIndex(full => full.FlightId === activity.FlightId);
    if (baseIndex < 0) {
        return false;
    }
    for (const activity of activities.slice(baseIndex + 1)) {
        if (activity.ActivityType === "Flight") {
            return true;
        } else if (activity.ActivityType === "Deadhead") {
            return false;
        }
    }
    for (const activity of activities.slice(0, baseIndex).reverse()) {
        if (activity.ActivityType === "Flight") {
            return true;
        } else if (activity.ActivityType === "Deadhead") {
            return false;
        }
    }
    return false;
}

export function hasPositioningDesignator(fullActivity: RotationActivity | null | undefined) {
    return fullActivity
        && fullActivity.DutyDesignators
        && (
            fullActivity.DutyDesignators.includes("[P]") ||
            fullActivity.DutyDesignators.includes("[D]")
        );
}

function isTransportation(activity: RosterItem) {
    return activity.ActivityType === "Deadhead"
        || activity.FullRaidoActivity?.ActivitySubType === "Transport"
        || hasPositioningDesignator(activity.FullRotationActivity);
}

function beginsWithOffDay(sequence: RosterItem[]) {
    for (const activity of sequence) {
        if (activity.ActivityType === "BLANK") {
            continue;
        } else if (isOffDutyActivity(activity)) {
            return true;
        } else if (isTransportation(activity)) {
            continue;
        } else {
            return false;
        }
    }
}

function precededByOffDay(activity: LastRealActivity, days: RotationDay[]) {
    const activities = days.flatMap(d => d.Activities);
    const baseIndex = activities.findIndex(full => full.FlightId === activity.FlightId);
    if (baseIndex < 0) {
        return false;
    }
    const preceding = activities.slice(0, baseIndex).reverse();
    return beginsWithOffDay(preceding);
}

function followedByOffDay(activity: LastRealActivity, days: RotationDay[]) {
    const activities = days.flatMap(d => d.Activities);
    const baseIndex = activities.findIndex(full => full.FlightId === activity.FlightId);
    if (baseIndex < 0) {
        return false;
    }
    const following = activities.slice(baseIndex + 1);
    return beginsWithOffDay(following);
}

function isPositioningBetweenOffDays(activity: LastRealActivity, days: RotationDay[]) {
    const activities = days.flatMap(d => d.Activities);
    const baseIndex = activities.findIndex(full => full.FlightId === activity.FlightId);
    if (baseIndex < 0) {
        return false;
    }
    if (!isTransportation(activities[baseIndex])) {
        return false;
    }
    return precededByOffDay(activity, days)
        && followedByOffDay(activity, days);
}

function isOffDutyActivity(activity: { GNDACTCODETITLE: string | null | undefined, GroundActivityInfo: string | null }) {
    return activity.GNDACTCODETITLE === "LLV" // LONG TERM LEAVE
        || activity.GroundActivityInfo?.toUpperCase().includes("ROTATION")
        || activity.GroundActivityInfo?.toUpperCase().includes("UNPAID")
        || activity.GroundActivityInfo?.toUpperCase().includes("OFF PERDIEM")
        || activity.GroundActivityInfo?.toUpperCase().includes("OFF PER DIEM")
        || activity.GNDACTCODETITLE === "SVX"
        || activity.GNDACTCODETITLE === "CBO"
        || activity.GNDACTCODETITLE === "EML"
        || activity.GNDACTCODETITLE === "WFL"
        || activity.GNDACTCODETITLE === "SVV"
        || activity.GNDACTCODETITLE?.match(/^R\d+$/);
}

export function consideredOffPayNiaStay(nias: Set<string>, activity: LastRealActivity, days: RotationDay[]) {
    if (isOffDutyActivity(activity)) {
        return true;
    }
    if (!activity.AirportFrom || !activity.AirportTo) {
        return false;
    }
    const isNia = nias.has(activity.AirportFrom) && nias.has(activity.AirportTo);
    return isNia && !isDutyStop(activity, days);
}

type GroundActivityCategory = "ON_DUTY" | "OFF_DUTY_UNPAID" | "OFF_DUTY_PAID" | "TRAINING" | "POSITIONING" | "FACT" | "ILLNESS";

function guessCategoryFromGroundActivityName(text: string): GroundActivityCategory | null {
    text = text.toUpperCase();
    if (text.includes("DUTY") && !text.includes("EXCL. DUTY") || text.includes("SHIFT")) {
        return "ON_DUTY";
    } else if (text.match(/\bILL/) || text.match(/\bSICK/)) {
        return "ILLNESS";
    } else if (text.includes("STANDBY")
            || text.includes("STBY")
            || text.includes("LEAVE")
            || text.includes("ROTATION")
            || text.includes("RESERVE")
            || text.includes("VACATION")
            || text.match(/\bVAC\b/)
            || text.match(/\bOFF\b/)
    ) {
        return "OFF_DUTY_UNPAID";
    } else if (text.includes("TRN")
            || text.includes("SIMULATOR")
            || text.match(/\bSIM\b/)
            || text.includes("TRAINING")
            || text.includes("INSTRUCT")
            || text.includes("COURSE")
    ) {
        return "TRAINING";
    } else if (text.includes("HTL")
            || text.includes("NOHTL")
            || text.includes("HOTEL")
            || text.includes("APARTMENT")
    ) {
        return "OFF_DUTY_PAID";
    } else if (text.includes("POS")
            || text.includes("DEADHEAD")
            || text.includes("TRANSPORT")
    ) {
        return "POSITIONING";
    } else {
        return null;
    }
}

function getRaidoGroundActivityCategory(raidoActivity: RosterActivity): null | "ON_DUTY" | "OFF_DUTY_UNPAID" | "OFF_DUTY_PAID" | "TRAINING" | "POSITIONING" | "FACT" | "ILLNESS" {
    if (raidoActivity.ActivityCode === "PXP") {
        // in reference mapping it's categorized as off day, but it's applicable
        // for daily fee, the only thing off in this activity is Per Diem
        return "ON_DUTY";
    } else if (raidoActivity.ActivitySubType === "Transport") {
        return "POSITIONING";
        // "DayOff"
    } else if (raidoActivity.ActivitySubType === "Hotel") {
        return "OFF_DUTY_PAID";
    } else if (raidoActivity.ActivitySubType === "Illness") {
        return "ILLNESS";
    } else if (raidoActivity.ActivitySubType === "Vacation"
            || raidoActivity.ActivitySubType === "DayOff"
            || raidoActivity.ActivityCode.match(/^R\d+$/)
    ) {
        return "OFF_DUTY_UNPAID";
    } else if (raidoActivity.ActivitySubType === "Shift"
            || raidoActivity.ActivitySubType === "StandBy"
            || raidoActivity.ActivitySubType === "Compensation"
            || raidoActivity.ActivityCode === "HRD"
    ) {
        return "ON_DUTY";
    } else if (raidoActivity.ActivitySubType === "Training"
            || raidoActivity.ActivitySubType === "Simulator"
    ) {
        return "TRAINING";
    }
    const referenceActivity = aaiRaidoActivityTypes.get(raidoActivity.RefUniqueId);
    if (!referenceActivity) {
        return null;
    } else {
        return guessCategoryFromGroundActivityName(referenceActivity.Name);
    }
}

export function getGroundActivityCategory(activity: LastRealActivity | BlankActivity): null | GroundActivityCategory {
    if (activity.FullRaidoActivity) {
        return getRaidoGroundActivityCategory(activity.FullRaidoActivity);
    }
    const code = activity.GroundActivityCode;
    if (!code || !+code) {
        return null;
    }
    const typeRecord = aaiActivityTypes.find(tr => tr.Code === +code);
    if (typeRecord) {
        return "Category" in typeRecord ? typeRecord.Category : null;
    }
    if (!activity.GroundActivityInfo) {
        return null;
    }
    return guessCategoryFromGroundActivityName(activity.GroundActivityInfo);
}

export function isSccmJob(jobTitle: string) {
    return jobTitle === "SENIOR CABIN CREW GRADE 2"
        || jobTitle === "SENIOR CABIN CREW MEMBER";
}

export function isCabinCrewDepartment(department: string) {
    return [625, 626, 627].includes(+department);
}

export function isFlightDeckDepartment(department: string) {
    return [620, 622, 623, 630].includes(+department);
}

export function isLoadMasterDepartment(department: string) {
    return [645].includes(+department);
}

export function isMaintenanceDepartment(department: string) {
    return [530, 540, 550, 560, 585].includes(+department);
}

export function isAirAtlanta(company: CompanyName) {
    company = toUpperCase(company);
    return company === "AIRBORNE - MALTA"
        || company === "HEL - AAIUSD"
        || company === "D2W - AAIUSD";
}

/**
 * @param days = [1.2, 1.3, 1.4, 1.7, 1.9, 1.10, 1.15, 1.16, 1.17, 2.2, 2.3, 2.4]
 * @return number[][] = [[1.2, 1.3, 1.4], [1.7], [1.9, 1.10], [1.15, 1.16, 1.17], [2.2, 2.3, 2.4]]
 */
export function groupSequences(days: RotationDay[]): RotationDay[][] {
    if (days.length === 0) {
        return [];
    }
    days = [...days];
    const sequences: RotationDay[][] = [[days.shift() ?? neverNull()]];
    for (const day of days) {
        const lastSequence = sequences[sequences.length - 1];
        const lastDay = lastSequence[lastSequence.length - 1];
        if (incrementDay(lastDay.Date) === day.Date) {
            lastSequence.push(day);
        } else {
            sequences.push([day]);
        }
    }
    return sequences;
}

export function assertPositioning(activity: RosterItem): RosterItem & { ActivityType: "Deadhead" | "Flight" } | null {
    if (activity.ActivityType === "Deadhead") {
        return { ...activity, ActivityType: "Deadhead" };
    } else if (activity.ActivityType === "Flight") {
        const dutyDesignatorsAccessible = !!activity.FullRotationActivity;
        if (!dutyDesignatorsAccessible) {
            // if we don't have full roster with duty designators
            // data, it is safer to assume all flights to be positioning
            return { ...activity, ActivityType: "Flight" };
        } else {
            const isPositioning = activity.FullRotationActivity?.DutyDesignators?.match(/\[[DP]]/);
            return isPositioning ? { ...activity, ActivityType: "Flight" } : null;
        }
    } else {
        return null;
    }
}

export function getDutyFlights(activities: RosterItem[]) {
    return activities
        .filter(a => !assertPositioning(a))
        .flatMap(a => a.ActivityType === "Flight" ? [a] : []);
}

export function getFlightBlockMinutes(activity: RosterItem) {
    return +(activity.BlockHours ?? 0) * 60 + +(activity.BlockMinutes ?? 0);
}

export function getDeadheadBlockMinutes(activity: RosterItem & { ActivityType: "Deadhead" }) {
    return +(activity.DeadheadHours ?? 0) * 60 + +(activity.DeadHeadMinutes ?? 0);
}

export function getPositioningBlockMinutes(activity: RosterItem) {
    if (activity.ActivityType === "Deadhead") {
        return getDeadheadBlockMinutes({ ...activity, ActivityType: "Deadhead" });
    } else {
        return getFlightBlockMinutes(activity);
    }
}

export function countFlatDutyBlockMinutes(allActivities: RosterItem[]) {
    return getDutyFlights(allActivities)
        .map(getFlightBlockMinutes)
        .reduce((a, b) => a + b, 0);
}

export function countDutyBlockMinutes(rotationDays: RotationDay[]) {
    const allActivities = rotationDays.flatMap(rd => rd.Activities);
    return countFlatDutyBlockMinutes(allActivities);
}

export function endsPastMidnightOf(rotationDay: RaidoRotationDay, followingDay: RaidoRotationDay): boolean {
    const lastActivity = rotationDay.Activities.filter(a => a.ActivityType !== "BLANK").slice(-1)[0];
    if (!lastActivity) {
        return false;
    }
    if (lastActivity.ActivityType === "BLANK") {
        throw new Error("BLANK activity type assertion failed after filtering");
    }
    const isOverridable =
        lastActivity.GNDACTCODETITLE === "HTL" ||
        lastActivity.GNDACTCODETITLE === "NOHTL" ||
        lastActivity.GNDACTCODETITLE === "PXP";
    const isExplicitOffDay = followingDay.Activities
        .some(a => a.ActivityType !== "BLANK" && isOffDutyActivity(a));
    if (isOverridable && isExplicitOffDay) {
        // Hotel activity often lasts longer than 24 hours, covering multiple following days
        // sometimes some of those following days are marked as RCC, which may be interpreted as either a mistake
        // on crew planning side or as a way to say "this guy was staying in hotel _willingly_ on his off day, so he should not get paid"
        // I'm assuming the latter here
        return false;
    }
    return getLocalEndDate(lastActivity.FullRaidoActivity) >= followingDay.Date;
}

export function getMeaningfulActivity(activity: RosterItem, lastDutyKind: DutyKind, lastRealActivity: LastRealActivity) {
    const isActivityMeaningful = activity.ActivityType !== "BLANK" && (
        activity.ActivityType !== "Ground" ||
        activity.GNDACTCODETITLE !== "LEE" &&
        activity.GNDACTCODETITLE !== "TRH" &&
        activity.GNDACTCODETITLE !== "TRA"
    );
    return isActivityMeaningful ? activity : {
        ...lastDutyKind === "OFF_DUTY" ? lastRealActivity : {
            GroundActivityCode: null,
            GroundActivityInfo: null,
            GNDACTCODETITLE: null,
        },
        AirportFrom: lastRealActivity.AirportTo,
        AirportTo: lastRealActivity.AirportTo,
        FlightId: lastRealActivity.FlightId,
        FullRaidoActivity: lastRealActivity.FullRaidoActivity,
    };
}

export type LastRealActivity = {
    AirportFrom: string,
    AirportTo: IataAirport,
    GroundActivityCode: null | string,
    GroundActivityInfo: null | string,
    GNDACTCODETITLE: null| string,
    FlightId: number | 0,
    FullRaidoActivity?: RosterActivity,
};

export function dutifyRotationDays(
    nias: Set<IataAirport>,
    inputRotationDays: RaidoRotationDay[]
): (RaidoRotationDay & DutyKindExtension)[] {
    const rotationDays: (RaidoRotationDay & DutyKindExtension)[] = inputRotationDays
        .map(ird => ({ ...ird, DutyKind: null }));
    let lastRealActivity: LastRealActivity = rotationDays
        .flatMap(rd => rd.Activities)
        .flatMap(r => r.ActivityType === "BLANK" ? [] : [r])
        .map(a => ({
            AirportFrom: a.AirportFrom,
            AirportTo: a.AirportFrom,
            GroundActivityCode: null,
            GroundActivityInfo: null,
            GNDACTCODETITLE: null,
            FlightId: -100,
            FullRaidoActivity: undefined,
        }))[0];
    if (!lastRealActivity) {
        for (const rotationDay of rotationDays) {
            rotationDay.DutyKind = "OFF_DUTY";
        }
        return rotationDays;
    }
    let lastDutyKind: DutyKind = "OFF_DUTY";
    // TODO: account for past month
    let paidSickDays = 0;
    for (let i = 0; i < rotationDays.length; ++i) {
        const rotationDay = rotationDays[i];
        for (let j = 0; j < i; ++j) {
            const lastRotationDay = rotationDays[j];
            const countedPastMidnight =
                endsPastMidnightOf(lastRotationDay, rotationDay) &&
                getGroundActivityCategory(lastRealActivity) !== "OFF_DUTY_UNPAID" &&
                getGroundActivityCategory(lastRealActivity) !== "ILLNESS" &&
                getGroundActivityCategory(lastRealActivity) !== "FACT";
            if (countedPastMidnight) {
                if (lastDutyKind === "TRAVEL") {
                    rotationDay.DutyKind = "DAY_SHIFT_TRAVEL_END";
                } else if (lastDutyKind === "NIA_GROUND_DUTY") {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            }
        }
        const realActivities = rotationDay.Activities.flatMap(a => a.ActivityType !== "BLANK" ? [a] : []);
        if (realActivities.length === 0 &&
            !rotationDay.DutyKind &&
            getGroundActivityCategory(lastRealActivity) === "POSITIONING" &&
            nias.has(lastRealActivity.AirportTo) &&
            followedByOffDay(lastRealActivity, rotationDays)
        ) {
            lastDutyKind = "OFF_DUTY";
            rotationDay.DutyKind = "OFF_DUTY";
            continue;
        }
        for (const activity of rotationDay.Activities) {
            lastRealActivity = getMeaningfulActivity(activity, lastDutyKind, lastRealActivity);
            const groundActivityCategory = getGroundActivityCategory(lastRealActivity);
            let isOffDuty = false;
            if (isPositioningBetweenOffDays(lastRealActivity, rotationDays)) {
                isOffDuty = true;
            } else if (!consideredOffPayNiaStay(nias, lastRealActivity, rotationDays)
                    && !(groundActivityCategory === "ILLNESS" && lastDutyKind === "OFF_DUTY" && i > 0)
                    || groundActivityCategory === "POSITIONING"
                    || lastRealActivity.GNDACTCODETITLE === "HTL"
                    || lastRealActivity.GNDACTCODETITLE === "NOHTL"
                    || lastRealActivity.GNDACTCODETITLE === "OFF" // OFF = day off on operational base
            ) {
                lastDutyKind = "TRAVEL";
                rotationDay.DutyKind = "TRAVEL";
            } else if (groundActivityCategory === "ON_DUTY"
                    || groundActivityCategory === "TRAINING"
            ) {
                lastDutyKind = "NIA_GROUND_DUTY";
                if (rotationDay.DutyKind !== "TRAVEL" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_END" &&
                    rotationDay.DutyKind !== "DAY_SHIFT_TRAVEL_START"
                ) {
                    rotationDay.DutyKind = "NIA_GROUND_DUTY";
                }
            } else {
                isOffDuty = true;
            }
            if (isOffDuty) {
                lastDutyKind = "OFF_DUTY";
                if (!rotationDay.DutyKind) {
                    rotationDay.DutyKind = "OFF_DUTY";
                }
            }
        }
        if (rotationDay.Activities.some(a => getGroundActivityCategory(a) === "ILLNESS")) {
            if (rotationDay.DutyKind === "TRAVEL" ||
                rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_END" ||
                rotationDay.DutyKind === "DAY_SHIFT_TRAVEL_START"
            ) {
                ++paidSickDays;
                if (paidSickDays > 7) {
                    rotationDay.DutyKind = "OFF_DUTY";
                }
            } else if (rotationDay.DutyKind === "NIA_GROUND_DUTY") {
                ++paidSickDays;
            }
        }
    }
    return rotationDays;
}

type FetchedCalculationData = {
    rotationDays: RaidoRotationDay[],
    latestContractData: SignedDocumentRowBase | null,
};

export type PersonMonthRosterLocator = AbsoluteMonth & {
    navEntry: NavContractorBase,
    rdbEntry?: RdbContractor,
};

export type PersonFeeCalculationParams = FetchedCalculationData & PersonMonthRosterLocator;

export function fixManually(rosterLocator: AbsoluteMonth, message: string): ImpersonalSalaryJournalRecord[] {
    const fixManuallyBase = {
        fee_entry_type: FeeEntryType(9000),
        units: "1",
        payment_per_unit: "0",
        service_start_time: getMonthStartDate(rosterLocator),
        service_end_time: getMonthEndDate(rosterLocator),
    } as const;

    return [{
        ...fixManuallyBase,
        description: "Fix Manually - " + message,
    }];
}

export function parseRotationPeriod(title: string) {
    if (title.includes("21/14")) {
        return 21;
    } else if (title.includes("24/12")) {
        return 24;
    } else if (title.includes("WW")) {
        return WW_ROTATION_PERIOD;
    } else {
        return null;
    }
}

export function parseContract(record: SignedDocumentRowBase) {
    let title = null;
    let rotationPeriod = null;
    let nia: IataAirport | null = null;
    let dailyFee: `${number}` | null = null;
    let dailyFeeRaw: string | null = null;
    for (const entry of record.remaining_details) {
        if (!("key" in entry)) {
            if (!("number" in entry) && !title && !entry.raw.includes("SERVICE COMPANY")) {
                const match = entry.raw.match(/^.*SCHEDULE \S+\s+(\S.*)$/);
                if (match) {
                    title = match[1].replace(/DETAILS\s+OF\s+THE\s+INDIVIDUAL\s*/, "");
                    rotationPeriod = parseRotationPeriod(title) ?? rotationPeriod;
                }
            }
            continue;
        }
        if (entry.key.toUpperCase().replace(/\s+/g, "").includes("DAILYFEE")) {
            dailyFeeRaw = entry.value;
            const match = dailyFeeRaw.replace(/\s+/g, "")
                .match(/^\$?(?:USD?\s*|)\$?(\d+(\.\d{2})?)$/);
            if (match) {
                dailyFee = match[1] as `${number}`;
            }
        } else if (entry.key.toUpperCase().replace(/\s+/g, "").includes("NEARESTINTERNATIONALAIRPORT")
                || entry.key.toUpperCase().replace(/\s+/g, "").includes("NIA")
        ) {
            const value = entry.value.trim().toUpperCase();
            if (value.length === 3) {
                nia = value as IataAirport;
            }
        }
    }
    return { title, rotationPeriod, nia, dailyFee, dailyFeeRaw };
}

export async function fetchMonthRotationDays(
    params: NavInvoiceScreenParams,
    prefetchedNoc: MonthStrToCrewMap = new Map()
): Promise<RaidoRotationDay[] | null> {
    const monthStr = getMonthStr(params);
    const prefetchedMonth = prefetchedNoc.get(monthStr);
    const nocCrew = prefetchedMonth
        ? prefetchedMonth.get(toCrewCodeUc(params.employeeCode))
        : await externalRostersApi.getAirAtlantaRaidoRoster({
            crew_code: params.employeeCode,
            year: params.year,
            month: params.month,
    }).catch(error => {
        if (error instanceof HttpResponseError && (
            error.response.status === 404 ||
            error.response.status === 406
        )) {
            return null;
        } else {
            throw error;
        }
    });
    if (!nocCrew) {
        return null;
    }
    const rmifiedNoc = raidoToRm(nocCrew, params);
    return getRotationDaysFromFullUnfiltered(rmifiedNoc, null);
}

export function getBestError(personRoster: PersonMonthRosterContent) {
    return personRoster.roster_complete?.ResultMessage
        ?? personRoster.roster_complete_error;
}

export function isSoldDayOff(day: RotationDay) {
    return day.Activities.some(activity => {
        if (activity.ActivityType !== "Ground") {
            return false;
        }
        return activity.GroundActivityInfo?.toUpperCase() === "EXTRA FLIGHT ON OFF DAY"
            || activity.GroundActivityInfo?.toUpperCase() === "WORKED OFF DAY"
            || activity.GroundActivityInfo?.toUpperCase() === "EXTRA PAY ON DAY OFF"
            || activity.GNDACTCODETITLE === "XCO"
            || activity.GNDACTCODETITLE === "WOFF"
            || activity.GNDACTCODETITLE === "HRD"
            || activity.GNDACTCODETITLE === "ECD" // not sure
            || activity.GNDACTCODETITLE === "BOD" // not sure
            ;
    });
}

export function getNias(person: {
    navEntry: NavContractorBase | null,
    rdbEntry?: { "Nearest_Airport": string | null },
    latestContractData: SignedDocumentRowBase | null,
}) {
    const nias = new Set<IataAirport>();
    if (person.latestContractData) {
        const parsed = parseContract(person.latestContractData);
        if (parsed.nia) {
            nias.add(parsed.nia);
        }
    }
    if (person.navEntry?.NearestIntAirport) {
        nias.add(person.navEntry.NearestIntAirport);
    }
    const rdbNias = person.rdbEntry?.Nearest_Airport?.split(/[^a-zA-Z]+/)
        .map(a => a.toUpperCase() as IataAirport)
        .filter(a => a.length >= 3 && a.length <= 4) ?? [];
    if (rdbNias.length > 1) {
        for (const rdbNia of rdbNias) {
            nias.add(rdbNia);
        }
    }
    return nias;
}
