import { assertSubTree } from "./testingHelpers";
import { fetchMonthRotationDays } from "../features/roster/air_atlanta/FeeCalculatorUtils";
import { calculateFeesForFlightDeckPerson,getPrecedingUnterminatedRotationDays } from "../features/roster/air_atlanta/FeeCalculatorFlightDeck";
import { brand, neverNull,toCrewCodeUc } from "../utils/typing";
import { FeeEntryType } from "../features/ExternalRostersApi";
import type { ImpersonalSalaryJournalRecord  } from "../features/ExternalRostersApi";
import { rowsToObjects } from "../utils/dataHelpers";
import type { IataAirport } from "../types/utility";
import type { OfficerFlightDeckFees } from "../types/Api/Contract";
import type { CrewCode } from "@mhc/utils/types/nav";

function Input(year: number, month: number, No_: string, NearestIntAirport: string, officerFees: OfficerFlightDeckFees | null) {
    return {
        year,
        month,
        No_: toCrewCodeUc(brand<CrewCode>(No_)),
        NearestIntAirport: brand<IataAirport>(NearestIntAirport),
        officerFees,
    } as const;
}

const DAILY_FEE = FeeEntryType(200);
const SICKNESS_DAILY_FEE = FeeEntryType(281);
const PER_DIEM = FeeEntryType(500);
const OVER_TIME_FD = FeeEntryType(300);
const INSTRUCTOR_FEE = FeeEntryType(670);
const PHONE_AND_INTERNET_ALLOWANCE = FeeEntryType(4005);

type TestCase = {
    title: string,
    input: ReturnType<typeof Input>,
    output: Partial<Omit<ImpersonalSalaryJournalRecord, "fee_entry_type"> & { fee_entry_type: number }>[],
};

const WWCPTINS_12 = { dailyFee: "501", rotationType: "WW", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const INSWW501 = { dailyFee: "501", rotationType: "WW", instructorFee: "1200", telecommunicationsAllowance: "20" } as const;
const INS2114516 = { dailyFee: "516", rotationType: "21/14", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const INS2114541 = { dailyFee: "541", rotationType: "21/14", instructorFee: "900", telecommunicationsAllowance: "20" } as const;
const FOAAI_4 = { dailyFee: "311", rotationType: "WW" } as const;
const FO2412311 = { dailyFee: "311", rotationType: "21/14" } as const;
const SFO370 = { dailyFee: "370", rotationType: "WW" } as const;
const FOFIXED_5 = { dailyFee: "319", rotationType: "21/14" } as const;
const FOWW370 = { dailyFee: "370", rotationType: "WW" } as const;
const FOWW390 = { dailyFee: "390", rotationType: "WW" } as const;
const CPT329 = { dailyFee: "329", rotationType: "24/12", telecommunicationsAllowance: "20" } as const;
const CPT2412450 = { dailyFee: "450", rotationType: "24/12", telecommunicationsAllowance: "20" } as const;
const WWCPTNEW_6 = { dailyFee: "427", rotationType: "WW", telecommunicationsAllowance: "20" } as const;
const CPTAAI_6 = { dailyFee: "438", rotationType: "WW", telecommunicationsAllowance: "20" } as const;
const WWCPT501 = { dailyFee: "501", rotationType: "WW", telecommunicationsAllowance: "20" } as const;

const testCases: TestCase[] = [
    {
        title: "Rotation dates range should be defined by positioning local time, but flights applicable to the rotation should be defined by UTC. It's counter-intuitive, but it's more fair since LT-based days are non-deterministic",
        input: Input(2024, 11, "CHFL", "BGO", { "aircraft":"B747-400","dailyFee":"474","telecommunicationsAllowance":"20","rotationType":"24/12" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-11-01","2024-11-15","474.00","Daily Fee"],
            [200,"3","2024-11-16","2024-11-18","711.00","Daily Fee x1.5"],
            [200,"4","2024-11-19","2024-11-22","948.00","Daily Fee x2"],
            [200,"2","2024-11-29","2024-11-30","948.00","Daily Fee x2"],
            [500,"22","2024-11-01","2024-11-22","85","Per diem"],
            [500,"2","2024-11-29","2024-11-30","85","Per diem"],
            [300,"37.50","2024-10-19","2024-11-17","184.86","Over time FD"],
            [4005,"1","2024-11-01","2024-11-30","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "OFF activity should be paid since it means OFF Day at Operational Base",
        input: Input(2024, 11, "NIMR", "MAD", { "aircraft":"B747-400","dailyFee":"500","telecommunicationsAllowance":"20","rotationType":"WW" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-11-01","2024-11-15","500.00","Daily Fee"],
            [200,"3","2024-11-16","2024-11-18","750.00","Daily Fee x1.5"],
            [200,"12","2024-11-19","2024-11-30","1000.00","Daily Fee x2"],
            [500,"30","2024-11-01","2024-11-30","85","Per diem"],
            [300,"15.08","2024-10-24","2024-11-22","195.00","Over time FD"],
            [4005,"1","2024-11-01","2024-11-30","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "Rotation must start from the day of positioning, which is 2nd of November in this case, and must end after 30 days",
        input: Input(2024, 12, "TOBE", "VIE", { "aircraft":"B747-400","dailyFee":"329","rotationType":"WW" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-12-01","2024-12-15","329.00","Daily Fee"],
            [200,"3","2024-12-16","2024-12-18","493.50","Daily Fee x1.5"],
            [200,"5","2024-12-19","2024-12-23","658.00","Daily Fee x2"],
            [200,"5","2024-12-26","2024-12-30","658.00","Daily Fee x2"],
            [200,"2","2024-12-24","2024-12-25","987.00","Daily Fee x3"],
            [200,"1","2024-12-31","2024-12-31","987.00","Daily Fee x3"],
            [500,"31","2024-12-01","2024-12-31","85","Per diem"],
            [300,"18.05","2024-11-02","2024-12-01","128.31","Over time FD"],
            [300,"33.05","2024-12-02","2024-12-31","128.31","Over time FD"],
        ),
    },
    {
        title: "Explicit off day > PXP on 21st of November",
        input: Input(2024, 11, "COEE", "AMS", { "aircraft":"B747-400","dailyFee":"373","rotationType":"24/12" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-11-01","2024-11-15","373.00","Daily Fee"],
            [200,"3","2024-11-16","2024-11-18","559.50","Daily Fee x1.5"],
            [200,"2","2024-11-19","2024-11-20","746.00","Daily Fee x2"],
            [500,"18","2024-11-01","2024-11-18","85","Per diem"],
        ),
    },
    {
        title: "Cargo season x1.5 day overlaps with sickness - should still pay 150%",
        input: Input(2024, 11, "CHES", "CDG", { "aircraft":"B747-400","dailyFee":"550","telecommunicationsAllowance":"20","rotationType":"WW" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"14","2024-11-01","2024-11-14","550.00","Daily Fee"],
            [281,"1","2024-11-15","2024-11-15","550.00","Sickness 100%"],
            [200,"3","2024-11-16","2024-11-18","825.00","Daily Fee x1.5"],
            [200,"5","2024-11-19","2024-11-23","1100.00","Daily Fee x2"],
            [500,"23","2024-11-01","2024-11-23","85","Per diem"],
            [300,"9.98","2024-10-25","2024-11-23","214.50","Over time FD"],
            [4005,"1","2024-11-01","2024-11-30","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "Deadhead and Positioning Own Time surrounded by off duty days should not be considered duty",
        input: Input(2024, 9, "STIM", "MNL", INS2114516),
        output: rowsToObjects(
            ["fee_entry_type"             , "units" , "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,  "10"   ,         "2024-09-01",       "2024-09-10"],
            [DAILY_FEE                    ,   "6"   ,         "2024-09-25",       "2024-09-30"],
            [PER_DIEM                     ,  "10"   ,         "2024-09-01",       "2024-09-10"],
            [PER_DIEM                     ,   "6"   ,         "2024-09-25",       "2024-09-30"],
            [OVER_TIME_FD                 ,  "32.42",         "2024-08-19",       "2024-09-10"],
            [INSTRUCTOR_FEE               ,   "1"   ,         "2024-09-01",       "2024-09-30"],
            [PHONE_AND_INTERNET_ALLOWANCE ,   "1"   ,         "2024-09-01",       "2024-09-30"],
        ),
    },
    {
        title: "Last day starts a rotation with positioning activity - should count as duty... will need to check next month roster for that to tell this case apart from the case when positioning is between off days",
        input: Input(2024, 10, "TOTU", "PRG", INSWW501),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,    "12",         "2024-10-01",       "2024-10-12"],
            [DAILY_FEE                    ,     "1",         "2024-10-31",       "2024-10-31"],
            [PER_DIEM                     ,    "12",         "2024-10-01",       "2024-10-12"],
            [PER_DIEM                     ,     "1",         "2024-10-31",       "2024-10-31"],
            [INSTRUCTOR_FEE               ,     "1",         "2024-10-01",       "2024-10-31"],
            [PHONE_AND_INTERNET_ALLOWANCE ,     "1",         "2024-10-01",       "2024-10-31"],
        ),
    },
    {
        title: "N-OC era overtime from rotation spanning across 2 months. Also demonstrates that rotation is reset on 30th day",
        input: Input(2024, 11, "BRUT", "BCN", { "aircraft":"B747-400","dailyFee":"500","telecommunicationsAllowance":"20","rotationType":"21/14" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-11-01","2024-11-15","500.00","Daily Fee"],
            [200,"3","2024-11-16","2024-11-18","750.00","Daily Fee x1.5"],
            [200,"12","2024-11-19","2024-11-30","1000.00","Daily Fee x2"],
            [500,"30","2024-11-01","2024-11-30","85","Per diem"],
            [300,"14.82","2024-10-14","2024-11-12","195.00","Over time FD"],
            [4005,"1","2024-11-01","2024-11-30","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "Continuation of the test case above, with example of how Cargo Season interact with Christmas bonuses and that rotation that was reset at 30th day gets picked up starting from the following day correctly in the next month",
        input: Input(2024, 12, "BRUT", "BCN", { "aircraft":"B747-400","dailyFee":"500","telecommunicationsAllowance":"20","rotationType":"21/21" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-12-01","2024-12-15","500.00","Daily Fee"],
            [200,"3","2024-12-16","2024-12-18","750.00","Daily Fee x1.5"],
            [200,"5","2024-12-19","2024-12-23","1000.00","Daily Fee x2"],
            [200,"5","2024-12-26","2024-12-30","1000.00","Daily Fee x2"],
            [200,"2","2024-12-24","2024-12-25","1500.00","Daily Fee x3"],
            [200,"1","2024-12-31","2024-12-31","1500.00","Daily Fee x3"],
            [500,"31","2024-12-01","2024-12-31","85","Per diem"],
            [300,"27.60","2024-11-13","2024-12-12","195.00","Over time FD"],
            [4005,"1","2024-12-01","2024-12-31","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "B777 must receive Christmas days bonus too, not just B747",
        input: Input(2024, 12, "JABL", "BUD", { "aircraft":"B777","dailyFee":"378","rotationType":"21/14" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"11","2024-12-12","2024-12-22","378.00","Daily Fee"],
            [200,"1","2024-12-23","2024-12-23","756.00","Daily Fee x2"],
            [200,"5","2024-12-26","2024-12-30","756.00","Daily Fee x2"],
            [200,"2","2024-12-24","2024-12-25","1134.00","Daily Fee x3"],
            [200,"1","2024-12-31","2024-12-31","1134.00","Daily Fee x3"],
            [500,"20","2024-12-12","2024-12-31","85","Per diem"],
        ),
    },
    {
        title: "Sickness fee entry type, not regular daily fee",
        input: Input(2024, 11, "JABL", "BUD", { "aircraft":"B777","dailyFee":"378","rotationType":"21/14" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"2","2024-11-01","2024-11-02","378.00","Daily Fee"],
            [200,"12","2024-11-08","2024-11-19","378.00","Daily Fee"],
            [200,"4","2024-11-23","2024-11-26","378.00","Daily Fee"],
            [281,"3","2024-11-20","2024-11-22","378.00","Sickness 100%"],
            [500,"2","2024-11-01","2024-11-02","85","Per diem"],
            [500,"19","2024-11-08","2024-11-26","85","Per diem"],
        ),
    },
    {
        title: "Cargo Season bonus example",
        input: Input(2024, 11, "NEPA", "LHR", { "aircraft":"B747-400","dailyFee":"474","telecommunicationsAllowance":"20","instructorFee":"1200","rotationType":"WW" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit", "description"],
            [200,"15","2024-11-03","2024-11-17","474.00","Daily Fee"],
            [200,"3","2024-11-24","2024-11-26","711.00","Daily Fee x1.5"],
            [200,"4","2024-11-27","2024-11-30","948.00","Daily Fee x2"],
            [500,"15","2024-11-03","2024-11-17","85","Per diem"],
            [500,"7","2024-11-24","2024-11-30","85","Per diem"],
            [670,"1","2024-11-01","2024-11-30","1200","Instructor fee 010811"],
            [4005,"1","2024-11-01","2024-11-30","20","Phone and Internet allowance"],
        ),
    },
    {
        title: "Cargo season bonus should not be applicable to B777 pilots - it is only for B747",
        input: Input(2024, 11, "GYLA", "BUD", { "aircraft":"B777","dailyFee":"487","telecommunicationsAllowance":"20","instructorFee":"1200","rotationType":"WW" }),
        output: rowsToObjects(
            ["fee_entry_type", "units", "service_start_time", "service_end_time", "payment_per_unit"],
            [200,"24","2024-11-07","2024-11-30","487.00"],
            [500,"24","2024-11-07","2024-11-30","85"],
            [670,"1","2024-11-01","2024-11-30","1200"],
            [4005,"1","2024-11-01","2024-11-30","20"],
        ),
    },
    {
        title: "First day spent at NIA, but blank - should not get counted as duty",
        input: Input(2024, 9, "WABI", "BRU", WWCPT501),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    ,    "19",         "2024-09-02",       "2024-09-20"],
            [DAILY_FEE                    ,     "1",         "2024-09-30",       "2024-09-30"],
            [PER_DIEM                     ,    "19",         "2024-09-02",       "2024-09-20"],
            [PER_DIEM                     ,     "1",         "2024-09-30",       "2024-09-30"],
            [PHONE_AND_INTERNET_ALLOWANCE ,     "1",         "2024-09-01",       "2024-09-30"],
        ),
    },
    {
        title: "Positioning surrounded by off duty days should not be considered duty",
        input: Input(2024, 9, "ABUY", "DAC", FOWW390),
        output: rowsToObjects(
            ["fee_entry_type"             , "units", "service_start_time", "service_end_time"],
            [DAILY_FEE                    , "20"   ,         "2024-09-08",       "2024-09-27"],
            [PER_DIEM                     , "19"   ,         "2024-09-08",       "2024-09-26"],
            [OVER_TIME_FD                 , "1.47" ,         "2024-09-08",       "2024-09-27"],
        ),
    },
];

async function getActualOutput(input: TestCase["input"]): Promise<ImpersonalSalaryJournalRecord[]> {
    const whenRotationDays = fetchMonthRotationDays({
        ...input, employeeCode: input.No_, company: brand("Airborne - Malta"),
    });
    const whenPrecedingUnterminatedRotationDays = getPrecedingUnterminatedRotationDays({
        ...input, employeeCode: input.No_, company: brand("Airborne - Malta"),
    });

    const params = {
        ...input,
        navEntry: {
            ...input,
            JobTitle: "CAPTAIN",
        },
        aircraft: input.officerFees?.aircraft ?? "",
        precedingUnterminatedRotationDays: await whenPrecedingUnterminatedRotationDays,
        rotationDays: await whenRotationDays ?? neverNull(),
        latestContractData: null,
    };
    return calculateFeesForFlightDeckPerson(params, input.officerFees);
}

export default async function FeeCalculatorFlightDeckTest() {
    let i = 0;
    for (const testCase of testCases) {
        console.info("Processing test case #" + (i++) + ": " + testCase.title);
        const actualOutput = await getActualOutput(testCase.input);
        assertSubTree(testCase.output, actualOutput);
        console.log("                     OK");
    }
};
