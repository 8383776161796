import type { DutiedRotationDay,RaidoActivityExtension, RotationDay } from "../roster/air_atlanta/RotationDaysGrouper";
import  { getFilterByMonth } from "../roster/air_atlanta/RotationDaysGrouper";
import  { getRotationDaysFromFull } from "../roster/air_atlanta/RotationDaysGrouper";
import { asUtc, estimateStartDateTime } from "../roster/air_atlanta/RotationDaysGrouper";
import type { ManualInvoiceDayFromRoster, ManualInvoiceOffDay } from "../../types/Api/Invoice";
import type { RosterItem } from "../roster/air_atlanta/Roster";
import { dutifyRotationDays,
    getBestError,
    getDutyFlights,
    getGroundActivityCategory
} from "../roster/air_atlanta/FeeCalculatorUtils";
import { formatDuration } from "../../utils/dates";
import type { RosterWithExtraData } from "./aaiMtxXlsxWorkInvoiceFiller";
import type { IsoDate,IsoDateTime } from "../../types/utility";
import type { IataAirport } from "@mhc/utils/types/utility";

function countDutyMinutes(duties: RosterItem[], rotationDay: RotationDay) {
    let sum = 0;
    for (const duty of duties) {
        const startTime = estimateStartDateTime(duty, rotationDay);
        const endTime = asUtc(duty.ActivityDate);
        if (!startTime) {
            return null;
        }
        const msDiff = endTime.getTime() - startTime.getTime();
        const minutesDiff = msDiff / 1000 / 60;
        sum += minutesDiff;
    }
    return sum;
}

/**
 * 2024-04-14 - 2024-04-05 = 9
 * 2024-03-01 - 2024-02-27 = 3
 * 2023-03-01 - 2023-02-27 = 2
 * 2023-03-02T00:00:00 - 2023-03-02T23:59:00 = 1
 */
function getNightsBetween(baseDate: string, targetDate: string) {
    const msDiff =
        new Date(targetDate.slice(0, 10)).getTime() -
        new Date(baseDate.slice(0, 10)).getTime();
    return Math.floor(msDiff / 24 / 60 / 60 / 1000);
}

function formatTimeUtc(timeStr: IsoDateTime, rotationDate: IsoDate | IsoDateTime) {
    const endTimeUtc = asUtc(timeStr);
    const endDateUtc = endTimeUtc.getUTCFullYear() + "-" +
        String((endTimeUtc.getUTCMonth() + 1)).padStart(2, "0") + "-" +
        String(endTimeUtc.getUTCDate()).padStart(2, "0");

    const dayShift = getNightsBetween(rotationDate, endDateUtc);
    return endTimeUtc.getUTCHours() + ":" + String(endTimeUtc.getUTCMinutes()).padStart(2, "0") +
        (!dayShift ? "" : dayShift < 0 ? " " + dayShift : " +" + dayShift);
}

export type BaseRowData = ManualInvoiceDayFromRoster | ManualInvoiceOffDay;

function getDayNightOvertimeUnits(rotationDay: RotationDay, precedingDay: RotationDay | null): number {
    let totalMinutes = 0;
    if (precedingDay) {
        for (const activity of getDutyFlights(precedingDay.Activities)) {
            const activityMinutes = +activity.BlockHours * 60 + +activity.BlockMinutes;
            const endDt = activity.FullRotationActivity?.ATA_UTC ?? activity.ActivityDate;
            const endDate = endDt.slice(0, 10);
            if (endDate > precedingDay.Date) {
                const msAfterMidnight = asUtc(endDt).getTime() - new Date(endDate).getTime();
                // Math.min() needed for cases when flight actual departure time was delayed to after midnight
                totalMinutes += Math.min(activityMinutes, msAfterMidnight / 1000 / 60);
            }
        }
    }
    for (const activity of getDutyFlights(rotationDay.Activities)) {
        let activityMinutes = +activity.BlockHours * 60 + +activity.BlockMinutes;
        const endDt = activity.FullRotationActivity?.ATA_UTC ?? activity.ActivityDate;
        const endDate = endDt.slice(0, 10);
        if (endDate > rotationDay.Date) {
            const msAfterMidnight = asUtc(endDt).getTime() - new Date(endDate).getTime();
            activityMinutes = Math.max(0, activityMinutes - msAfterMidnight / 1000 / 60);
        }
        totalMinutes += activityMinutes;
    }
    return totalMinutes / 60;
}

function isTimeInOutActivity(a: RosterItem) {
    if (a.ActivityType !== "Ground") {
        return false;
    }
        // RM codes follow
    return a.GNDACTCODETITLE === "MXD"
        || a.GNDACTCODETITLE === "MXN"
        || a.GNDACTCODETITLE === "MDS"
        || a.GNDACTCODETITLE === "MNS"
        // N-OC codes follow
        || a.GNDACTCODETITLE === "12h"
        || a.GNDACTCODETITLE === "8h";
}

function getBaseRowData(
    rotationDay: DutiedRotationDay,
    precedingDay: RotationDay | null,
    lastAirport: string | null | undefined
): BaseRowData {
    if (rotationDay.DutyKind === "OFF_DUTY") {
        const suffix = rotationDay.Activities
            .filter(a => getGroundActivityCategory(a) === "OFF_DUTY_UNPAID")
            .filter(a => a.GNDACTCODETITLE)
            .map(a => " (" + a.GNDACTCODETITLE + ")") || "";
        return {
            Base: "OFF DUTY" + suffix,
            PaymentOnOff: false,
        };
    }
    const groundDuties = rotationDay.Activities.filter(a => getGroundActivityCategory(a) === "ON_DUTY");
    const trainings = rotationDay.Activities.filter(a => getGroundActivityCategory(a) === "TRAINING");
    const dutyFlights = getDutyFlights(rotationDay.Activities);
    const allAirports = new Set(
        rotationDay.Activities.flatMap(a => [
            a.AirportFrom, a.AirportTo
        ].filter(a => a))
    );
    const groundDutyAirports = new Set(
        [...groundDuties, ...trainings].flatMap(a => [
            a.AirportFrom, a.AirportTo
        ].filter(a => a))
    );
    const location = dutyFlights.length === 0 &&
        [...groundDutyAirports].join("-") ||
        [...allAirports].join("-") || lastAirport || "";
    let Base = location;
    let groundActivitySuffix = "";
    if (groundDuties.length > 0) {
        groundActivitySuffix = " (" + groundDuties.map(a => a.GNDACTCODETITLE).join("/") + ")";
    } else if (trainings.length > 0) {
        groundActivitySuffix = " (TRAINING " + trainings.map(a => a.GNDACTCODETITLE).join("/") + ")";
    }
    if (dutyFlights.length > 0) {
        Base = dutyFlights[0].FlightNumber + " " + Base;
    } else if (groundActivitySuffix) {
        Base += groundActivitySuffix;
    } else if (allAirports.size > 1) {
        Base = "TRAVEL " + Base;
    }
    let timeInOutActivities =
        groundDuties.length > 0 ? groundDuties :
        trainings.length > 0 ? trainings :
        rotationDay.Activities.filter(a => {
            return a.ActivityType !== "BLANK"
                && getGroundActivityCategory(a) !== "OFF_DUTY_UNPAID";
        });
    if (timeInOutActivities.some(isTimeInOutActivity)) {
        // shift foremen don't actively maintain the times in the rostering system - they usually just put the
        // code and keep the default time in/out values, even though their shifts start/end at different times
        timeInOutActivities = [];
    }
    const rotationDate = rotationDay.Date;
    const times = [];
    for (let i = 0; i < 2; ++i) {
        const activity = timeInOutActivities[i];
        if (activity) {
            const timeOut = formatTimeUtc(activity.ActivityDate, rotationDate);
            let timeIn;
            const startTime = estimateStartDateTime(activity, rotationDay);
            if (startTime) {
                timeIn = formatTimeUtc(startTime.toISOString(), rotationDate);
            } else {
                timeIn = null;
            }
            times[i] = { timeIn, timeOut };
        }
    }
    let dutyMinutes = countDutyMinutes(groundDuties, rotationDay);
    let DailyHours;
    if (dutyMinutes === null) {
        DailyHours = null;
    } else if (dutyMinutes > 0) {
        const hasOutdated10hActivity = groundDuties.some(gd => {
            return gd.GNDACTCODETITLE === "MXD" || gd.GNDACTCODETITLE === "MXN";
        });
        if (dutyMinutes === 10 * 60 && hasOutdated10hActivity) {
            dutyMinutes = 12 * 60;
        }
        DailyHours = formatDuration(dutyMinutes);
    } else {
        DailyHours = null;
    }
    const BlockHours = getDayNightOvertimeUnits(rotationDay, precedingDay);
    return {
        Base,
        TimeIn1: times[0]?.timeIn ?? "",
        TimeOut1: times[0]?.timeOut ?? "",
        TimeIn2: times[1]?.timeIn ?? "",
        TimeOut2: times[1]?.timeOut ?? "",
        DailyHours: BlockHours > 0 ? [
            Math.floor(BlockHours),
            Math.floor(BlockHours % 1 * 60).toString().padStart(2, "0"),
        ].join(":") : DailyHours ?? "",
        AdditionalDay: false,
        BlockHours: BlockHours,
        PaymentOnOff: true,
    };
}

export function prepareDayRowsData({ personMonthRoster, profileNav }: RosterWithExtraData) {
    const dutylessRotationDays = getRotationDaysFromFull(
        personMonthRoster,
        personMonthRoster.roster_complete,
        personMonthRoster.roster_incomplete
    );
    if (!dutylessRotationDays) {
        throw new Error("Roster data unavailable: " + getBestError(personMonthRoster));
    }
    const nias = new Set<IataAirport>([profileNav.NearestIntAirport].filter(nia => nia?.trim()));
    const rotationDays = dutifyRotationDays(nias, dutylessRotationDays)
        .filter(getFilterByMonth<RaidoActivityExtension>(personMonthRoster));

    const results = [];
    let lastAirport = null;
    let lastDay = null;
    for (let i = 0; i < rotationDays.length; ++i) {
        const rotationDay = rotationDays[i];
        const baseRowData = getBaseRowData(rotationDay, lastDay, lastAirport);
        results.push(baseRowData);
        lastDay = rotationDay;
        lastAirport = rotationDay.Activities
            .flatMap(a => [a.AirportFrom, a.AirportTo])
            .filter(a => a).slice(-1)[0] ?? lastAirport;
    }
    return results;
}